import axios from 'axios';
import { BASE_URL } from './endpoint';

export const api = axios.create({
  baseUri: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  (config) => {
    let authRoute = config.url.includes('login') || config.url.includes('singup');
    if (!authRoute) {
      const token = localStorage.getItem('access_token');
      if (token && token !== 'undefined') {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  (res) => {
    if (res.data.error) {
      return Promise.reject(res.data);
    } else {
      return Promise.resolve(res.data);
    }
  },
  (error) => Promise.reject(error)
);
