import React from 'react';
import { Helmet } from 'react-helmet';
import { defaultMetaTitle, defaultMetaDescription, defaultMetaKeywords } from '../../Helper/global';

const HelmetComponent = ({ metaData }) => {
  const metaTitle = metaData?.meta_title || defaultMetaTitle;
  const metaDescription = metaData?.meta_description || defaultMetaDescription;
  const metaKeywords = metaData?.meta_keywords || defaultMetaKeywords;

  return (
    <Helmet key={metaTitle}>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
    </Helmet>
  );
};

export default HelmetComponent;
