/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Typography, Divider, useMediaQuery, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight, FilterList } from '@mui/icons-material';
import CheckBoxDropdown from './CheckBoxDropdown';
import Pill from '../common/Pill';
import CustomModal from '../common/CustomModal';
import { IndianZones } from '../../Helper/global';
import { useLocation } from 'react-router-dom';

const SideFilter = ({
  tourBudget,
  tourDuration,
  indianData,
  worldData,
  themeFilter,
  adventureTag,
  adventuresData,
  choosedCurrency,
  selectedState,
  sideFilterInitialItems,
  onCheckedItemsChange,
  isIndianCheckbox,
  isThemeCheckbox,
  isAdventureCheckbox,
  selectedContinent,
  selectedZone,
  tourCategory,
  isBreadcrumbClicked,
  setIsBreadcrumbClicked,
  pathSegments
}) => {
  const [isOpenBudget, setIsOpenBudget] = useState(true);
  const [isOpenDuration, setIsOpenDuration] = useState(true);
  const [isOpenTheme, setIsOpenTheme] = useState(true);
  const [isOpenAdventure, setIsOpenAdventure] = useState(true);
  const [isOpenIndia, setIsOpenIndia] = useState(true);
  const [isOpenWorld, setIsOpenWorld] = useState(true);
  const selectedTheme = themeFilter?.find((item) => item.isSelected);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const previousPathnameRef = useRef(location.pathname);

  const [checkedItems, setCheckedItems] = useState(() => {
    const initialItems = {
      'Tour Budget': { ...(sideFilterInitialItems?.['Tour Budget'] || {}) },
      'Tour Duration': { ...(sideFilterInitialItems?.['Tour Duration'] || {}) },
      'Indian Tours': { ...(sideFilterInitialItems?.['Indian Tours'] || {}) },
      'World Tours': { ...(sideFilterInitialItems?.['World Tours'] || {}) },
      'Tour Theme': { ...(sideFilterInitialItems?.['Tour Theme'] || {}) },
      Adventures: { ...(sideFilterInitialItems?.['Adventures'] || {}) }
    };

    if (Object.keys(initialItems['Indian Tours']).length === 0) {
      initialItems['Indian Tours'] = (indianData || []).reduce((acc, { value }) => {
        acc[value] = false;
        return acc;
      }, {});
    }

    if (Object.keys(initialItems['World Tours']).length === 0) {
      initialItems['World Tours'] = (worldData || []).reduce((acc, { value }) => {
        acc[value] = false;
        return acc;
      }, {});
    }

    if (Object.keys(initialItems['Tour Theme']).length === 0) {
      initialItems['Tour Theme'] = (themeFilter || []).reduce((acc, { value }) => {
        acc[value] = false;
        return acc;
      }, {});
    }
    return initialItems;
  });

  const anyChecked = Object.values(checkedItems).some((category) =>
    Object.values(category).some((value) => value === true)
  );

  useEffect(() => {
    const hasThemeInPath = location.pathname.includes('themed');
    const previousPathname = previousPathnameRef.current;

    if (previousPathname !== location.pathname) {
      if (!hasThemeInPath) {
        setCheckedItems((prevCheckedItems) => ({
          ...prevCheckedItems,
          'Tour Theme': {}
        }));
      }
    }
    previousPathnameRef.current = location.pathname;
  }, [location.pathname, sideFilterInitialItems]);

  // useEffect(() => {
  //   setCheckedItems(sideFilterInitialItems);
  // }, [location]);

  useEffect(() => {
    if (isBreadcrumbClicked) {
      handleClearAll();
      setIsBreadcrumbClicked(false);
    }
  }, [isBreadcrumbClicked]);

  useEffect(() => {
    const updateCheckedItems = () => {
      const newCheckedItems = { ...checkedItems };
      if (adventureTag) {
        newCheckedItems['Adventures'][adventureTag] = true;
      }

      if (selectedTheme) {
        newCheckedItems['Tour Theme'][selectedTheme.value] = selectedTheme.isSelected;
      }

      if (selectedState) {
        if (Object.keys(newCheckedItems['Indian Tours']).length === 0) {
          newCheckedItems['Indian Tours'][selectedState] = true;
        } else {
          Object.keys(newCheckedItems['Indian Tours']).forEach((key) => {
            newCheckedItems['Indian Tours'][key] = key === selectedState ? true : false;
          });
          newCheckedItems['Indian Tours'][selectedState] = true;
        }
        Object.keys(newCheckedItems['World Tours']).forEach((key) => {
          newCheckedItems['World Tours'][key] = false;
        });
      }

      if (selectedContinent) {
        if (Object.keys(newCheckedItems['World Tours']).length === 0) {
          newCheckedItems['World Tours'][selectedContinent] = true;
        } else {
          Object.keys(newCheckedItems['World Tours']).forEach((key) => {
            newCheckedItems['World Tours'][key] = key === selectedContinent ? true : false;
          });
          newCheckedItems['World Tours'][selectedContinent] = true;
        }
        Object.keys(newCheckedItems['Indian Tours']).forEach((key) => {
          newCheckedItems['Indian Tours'][key] = false;
        });
      }

      if (selectedZone && tourCategory === 'Domestic') {
        const statesInZone = IndianZones[selectedZone] || [];
        const updatedIndianTours = Object.keys(newCheckedItems['Indian Tours']).reduce(
          (acc, state) => {
            acc[state] = statesInZone.includes(state);
            return acc;
          },
          {}
        );
        newCheckedItems['Indian Tours'] = updatedIndianTours;
        Object.keys(newCheckedItems['World Tours']).forEach((key) => {
          newCheckedItems['World Tours'][key] = false;
        });
      }

      // Checked indian state based on selectedZone
      if (selectedZone && tourCategory === '' && selectedState === '' && indianData?.length > 0) {
        const statesInZone = IndianZones[selectedZone] || [];
        const updatedIndianTours = {};
        if (statesInZone.length > 0) {
          statesInZone.forEach((state) => {
            updatedIndianTours[state] = true;
          });
        }
        newCheckedItems['Indian Tours'] = updatedIndianTours;
        Object.keys(newCheckedItems['World Tours']).forEach((key) => {
          newCheckedItems['World Tours'][key] = false;
        });
      }

      // Checked all indian state if pathSegments[0] = india
      if (
        pathSegments[0] === 'india' &&
        selectedZone === '' &&
        tourCategory === '' &&
        selectedState === '' &&
        indianData?.length > 0
      ) {
        const updatedIndianTours = {};
        if (indianData?.length > 0) {
          indianData.forEach((state) => {
            updatedIndianTours[state.value] = true;
          });
        }
        newCheckedItems['Indian Tours'] = updatedIndianTours;
        Object.keys(newCheckedItems['World Tours']).forEach((key) => {
          newCheckedItems['World Tours'][key] = false;
        });
      }

      if (selectedContinent && tourCategory === 'International' && worldData.length > 0) {
        const updatedWorldTours = worldData.reduce((acc, { value }) => {
          acc[value] = value === selectedContinent;
          return acc;
        }, {});
        newCheckedItems['World Tours'] = updatedWorldTours;
        Object.keys(newCheckedItems['Indian Tours']).forEach((key) => {
          newCheckedItems['Indian Tours'][key] = false;
        });
      }
      setCheckedItems(newCheckedItems);
    };
    updateCheckedItems();
  }, [
    adventureTag,
    selectedTheme,
    selectedState,
    selectedContinent,
    selectedZone,
    tourCategory,
    worldData,
    anyChecked
  ]);

  useEffect(() => {
    if (tourCategory === 'Domestic' && selectedZone) {
      const statesInZone = IndianZones[selectedZone] || [];
      setCheckedItems((prevState) => {
        if (!prevState['Indian Tours']) {
          return prevState;
        }
        const updatedIndianTours = Object.keys(prevState['Indian Tours']).reduce((acc, state) => {
          acc[state] = statesInZone.includes(state);
          return acc;
        }, {});

        // Set all World Tours keys to false
        const updatedWorldTours = Object.keys(prevState['World Tours']).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});

        return {
          ...prevState,
          'Indian Tours': updatedIndianTours,
          'World Tours': updatedWorldTours
        };
      });
    }
  }, [selectedZone, tourCategory]);

  useEffect(() => {
    if (tourCategory === 'International' && selectedContinent) {
      const continentExists = worldData.some((continent) => continent.value === selectedContinent);
      if (continentExists) {
        setCheckedItems((prevState) => {
          const updatedWorldTours = worldData.reduce((acc, { value }) => {
            acc[value] = value === selectedContinent;
            return acc;
          }, {});

          // Set all Indian Tours keys to false
          const updatedIndiaTours = prevState['Indian Tours']
            ? Object.keys(prevState['Indian Tours']).reduce((acc, key) => {
                acc[key] = false;
                return acc;
              }, {})
            : {};

          return {
            ...prevState,
            'World Tours': updatedWorldTours,
            'Indian Tours': updatedIndiaTours
          };
        });
      }
    }
  }, [selectedContinent, tourCategory, worldData]);

  useEffect(() => {
    onCheckedItemsChange(checkedItems);
  }, [checkedItems, onCheckedItemsChange, selectedState]);

  useEffect(() => {
    if (selectedState) {
      handleRemovePill(selectedContinent);
    }
    if (selectedContinent) {
      handleRemovePill(selectedState);
    }
  }, [selectedState, selectedContinent]);

  const handleCloseModal = () => setOpen(false);
  const handleOpenModal = () => setOpen(true);
  const handleToggleBudget = () => setIsOpenBudget(!isOpenBudget);
  const handleToggleDuration = () => setIsOpenDuration(!isOpenDuration);
  const handleToggleIndia = () => setIsOpenIndia(!isOpenIndia);
  const handleToggleWorld = () => setIsOpenWorld(!isOpenWorld);
  const handleToggleTheme = () => setIsOpenTheme(!isOpenTheme);
  const handleToggleAdventure = () => setIsOpenAdventure(!isOpenAdventure);

  const handleRadioChange = (event, groupTitle) => {
    const { value } = event.target;
    setCheckedItems((prevState) => {
      const updatedCheckedItems = { ...prevState };

      if (groupTitle === 'World Tours') {
        Object.keys(updatedCheckedItems['Indian Tours']).forEach((key) => {
          updatedCheckedItems['Indian Tours'][key] = false;
        });
      }

      if (groupTitle === 'Indian Tours') {
        Object.keys(updatedCheckedItems['World Tours']).forEach((key) => {
          updatedCheckedItems['World Tours'][key] = false;
        });
      }

      const isCurrentlySelected = updatedCheckedItems[groupTitle][value];
      Object.keys(updatedCheckedItems[groupTitle]).forEach((key) => {
        updatedCheckedItems[groupTitle][key] = false;
      });

      if (!isCurrentlySelected) {
        updatedCheckedItems[groupTitle][value] = true;
      }
      return updatedCheckedItems;
    });
  };

  const handleCheckboxChange = (event, groupTitle) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => {
      const updatedCheckedItems = { ...prevState };

      if (groupTitle === 'Indian Tours') {
        Object.keys(updatedCheckedItems['World Tours']).forEach((key) => {
          updatedCheckedItems['World Tours'][key] = false;
        });
      }

      if (groupTitle === 'World Tours') {
        Object.keys(updatedCheckedItems['Indian Tours']).forEach((key) => {
          updatedCheckedItems['Indian Tours'][key] = false;
        });
      }

      return {
        ...updatedCheckedItems,
        [groupTitle]: {
          ...updatedCheckedItems[groupTitle],
          [name]: checked
        }
      };
    });
  };

  const handleClearAll = () => {
    setCheckedItems(sideFilterInitialItems);
  };

  useEffect(() => {
    const removeTourBudgetPills = () => {
      const tourBudgetOptions = tourBudget.map((option) => option.value);
      tourBudgetOptions.forEach((budgetValue) => {
        if (checkedItems['Tour Budget'] && checkedItems['Tour Budget'][budgetValue]) {
          handleRemovePill(budgetValue);
        }
      });
    };

    removeTourBudgetPills();
  }, [choosedCurrency]);

  const handleRemovePill = (optionValue) => {
    const updatedCheckedItems = { ...checkedItems };
    if (selectedContinent && optionValue === selectedContinent) {
      Object.keys(updatedCheckedItems['Indian Tours']).forEach((key) => {
        updatedCheckedItems['Indian Tours'][key] = false;
      });
    }
    if (selectedState && optionValue === selectedState) {
      Object.keys(updatedCheckedItems['World Tours']).forEach((key) => {
        updatedCheckedItems['World Tours'][key] = false;
      });
    }

    const isTourBudget = tourBudget.some((budgetOption) => budgetOption.value === optionValue);

    if (isTourBudget) {
      Object.keys(updatedCheckedItems['Tour Budget']).forEach((key) => {
        updatedCheckedItems['Tour Budget'][key] = false;
      });
    } else {
      for (const [groupTitle, groupItems] of Object.entries(updatedCheckedItems)) {
        if (groupItems[optionValue] !== undefined) {
          updatedCheckedItems[groupTitle][optionValue] = false;
          break;
        }
      }
    }
    // for (const [groupTitle, groupItems] of Object.entries(updatedCheckedItems)) {
    //   if (groupItems[optionValue] !== undefined) {
    //     updatedCheckedItems[groupTitle][optionValue] = false;
    //     break;
    //   }
    // }
    setCheckedItems(updatedCheckedItems);
  };

  const generatePills = () => {
    // eslint-disable-next-line no-unused-vars
    const selectedValues = Object.entries(checkedItems).reduce((acc, [groupTitle, groupItems]) => {
      Object.keys(groupItems).forEach((key) => {
        if (groupItems[key]) acc.push(key);
      });
      return acc;
    }, []);
    return selectedValues.map((value) => (
      <Pill key={value} title={value} onClick={() => handleRemovePill(value)} />
    ));
  };

  const hasSelectedItems = () =>
    Object.values(checkedItems).some((groupItems) =>
      Object.values(groupItems).some((isChecked) => isChecked)
    );

  const dropdownsData = [
    {
      title: 'Tour Budget',
      options: tourBudget,
      isOpen: isOpenBudget,
      isCheckbox: false,
      handleToggle: handleToggleBudget
    },
    {
      title: 'Tour Duration',
      options: tourDuration,
      isOpen: isOpenDuration,
      isCheckbox: false,
      handleToggle: handleToggleDuration
    },
    {
      title: 'Indian Tours',
      options: indianData,
      isOpen: isOpenIndia,
      isCheckbox: isIndianCheckbox,
      handleToggle: handleToggleIndia
    },
    {
      title: 'World Tours',
      options: worldData,
      isOpen: isOpenWorld,
      isCheckbox: false,
      handleToggle: handleToggleWorld
    },
    {
      title: 'Tour Theme',
      options: themeFilter,
      isOpen: isOpenTheme,
      isCheckbox: isThemeCheckbox,
      handleToggle: handleToggleTheme
    }
    // {
    //   title: 'Adventures',
    //   options: adventuresData,
    //   isOpen: isOpenAdventure,
    //   isCheckbox: isAdventureCheckbox,
    //   handleToggle: handleToggleAdventure
    // }
  ];
  const filterContent = (
    <Box
      className="filter-your-tour-left"
      bgcolor="white"
      sx={{
        borderTopLeftRadius: { xs: 12, md: 12 },
        borderTopRightRadius: { xs: 12, md: 12 },
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        overflow: 'hidden'
      }}
      mt={{ xs: 1, md: 0 }}>
      <Box
        sx={{
          width: { xs: '100%', md: '220px', lg: '300px' },
          position: 'relative',
          backgroundColor: '#FFF'
        }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px="15px"
          pt={isMobile ? '25px' : '15px'}>
          <ChevronLeft
            sx={{
              display: isMobile ? 'block' : 'none',
              cursor: 'pointer',
              width: 20,
              height: 20
            }}
            onClick={handleCloseModal}
          />
          <Typography
            fontSize={16}
            fontFamily="gothamBold"
            lineHeight="26px"
            color="#000000D9"
            flex={1}
            ml={isMobile && 2}>
            {isMobile ? 'Filter Your Trip!' : 'Filter your Tour'}
          </Typography>
          {hasSelectedItems() ? (
            <Button
              onClick={handleClearAll}
              sx={{
                fontSize: '12px',
                lineHeight: '16px',
                fontFamily: 'poppinsRegular',
                color: '#E41E25CC',
                textTransform: 'capitalize'
              }}>
              Clear All
            </Button>
          ) : null}
        </Box>
        {hasSelectedItems() ? (
          <Box
            mt="22px"
            display="flex"
            flexWrap="wrap"
            gap={1}
            height={80}
            pb={2.75}
            px="22px"
            sx={{ overflowY: 'scroll' }}>
            {generatePills()}
          </Box>
        ) : null}
        <Box sx={{ borderTop: '1px solid #0000001A !important' }}>
          {dropdownsData.map((dropdown, index) => (
            <Box key={index}>
              <CheckBoxDropdown
                title={dropdown.title}
                options={dropdown.options}
                selectedValues={checkedItems}
                handleRadioChange={(event) => handleRadioChange(event, dropdown.title)}
                handleCheckboxChange={(event) => handleCheckboxChange(event, dropdown.title)}
                isOpen={dropdown.isOpen}
                handleToggle={dropdown.handleToggle}
                isCheckbox={dropdown.isCheckbox}
              />
              {index !== dropdownsData.length - 1 && <Divider />}
            </Box>
          ))}
        </Box>
        <Button
          sx={{
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#E41E25',
            py: '13px',
            justifyContent: 'center',
            alignItems: 'center',
            variant: 'contained',
            display: isMobile ? 'flex' : 'none',
            ':hover': {
              backgroundColor: '#E41E25'
            }
          }}
          onClick={handleCloseModal}>
          <Typography
            fontFamily="gothamBold"
            fontSize={18}
            lineHeight="28px"
            color="#fff"
            mr="12px">
            Apply Filter
          </Typography>
          <ChevronRight sx={{ color: '#fff', width: 15, height: 15 }} />
          <ChevronRight sx={{ color: '#fff', width: 15, height: 15 }} />
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <>
          <Button
            onClick={handleOpenModal}
            startIcon={<FilterList />}
            variant="outlined"
            sx={{
              border: '1px solid #0000004D',
              color: '#000000CC',
              mb: 2,
              textTransform: 'capitalize'
            }}>
            Filter
          </Button>
          <CustomModal
            title={'Custom Modal'}
            open={open}
            onClose={handleCloseModal}
            sx={{
              height: '100vh',
              backgroundColor: '#fff',
              p: '21px',
              overflow: 'scroll',
              pb: { xs: '75px', md: 0 },
              width: '100%'
            }}>
            <>{filterContent}</>
          </CustomModal>
        </>
      ) : (
        <>{filterContent}</>
      )}
    </>
  );
};

export default SideFilter;
