import React, { useEffect, useState, useRef } from 'react';
import './WhyKesariSelect.css';
import { Grid } from '@mui/material';
import world_map from '../../../assest/PackageDetails/world_map.svg';
import { formatCount } from '../../../Helper/global';
import { API_URL, BASE_URL } from '../../../APIs/endpoint';
import { api } from '../../../APIs/api';
import Loader from '../../Loader/loader';

const CountingBox = ({ target, desc, loader }) => {
  const [count, setCount] = useState(0);
  const countingRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const handleIntersect = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const validTarget = isNaN(target) ? 0 : parseInt(target, 10);
          let currentCount = 0;
          const increment = Math.ceil(target / 100);
          const interval = setInterval(() => {
            currentCount += increment;
            setCount((prevCount) => Math.min(prevCount + increment, validTarget));
            if (currentCount >= target) {
              clearInterval(interval);
            }
          }, 10);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    if (countingRef.current) {
      observer.observe(countingRef.current);
    }

    return () => observer.disconnect();
  }, [target]);

  return (
    <Grid item xs={5} sm={4} md={3} lg={3} xl={3} ref={countingRef}>
      <div className="counter-content">
        {loader ? <Loader /> : <h2>{formatCount(count, desc)}</h2>}
        <span>{desc}</span>
      </div>
    </Grid>
  );
};

const WhyKesariSelect = () => {
  const [businessCount, setBusinessCount] = useState();
  const [isLoader, setIsLoader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const getBusinessCountsAPi = async () => {
    try {
      const businessCountRes = await api.get(`${BASE_URL}${API_URL.BUSINESS_COUNT}`);
      setIsLoader(true);
      if (businessCountRes.status) {
        setBusinessCount(businessCountRes.data);
        setIsLoader(false);
      } else {
        setError(businessCountRes.message || 'An error occurred');
        setIsLoader(false);
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getBusinessCountsAPi();
  }, []);

  return (
    <div className="why-kesari-select-container">
      <div className="why-kesari-heading-content-box">
        <div className="image-map">
          <img src={world_map} alt="Not Available Now" />
        </div>
        <div className="count-area-detail-box">
          <h5>Why Kesari Select ?</h5>
          <Grid container spacing={2}>
            <Grid container item xs={6} sm={6} md={6} lg={3} xl={3}>
              <CountingBox
                target={businessCount?.happy_customers || 0}
                desc="Happy Travellers"
                loader={isLoader}
              />
            </Grid>
            <Grid container item xs={6} sm={6} md={6} lg={3} xl={3}>
              <CountingBox target={businessCount?.repeat_customers || 0} desc="Repeat Travellers" />
            </Grid>
            <Grid container item xs={6} sm={6} md={6} lg={3} xl={3}>
              <CountingBox
                target={businessCount?.locations_covered || 0}
                desc="Locations Covered"
              />
            </Grid>
            <Grid container item xs={6} sm={6} md={6} lg={3} xl={3}>
              <CountingBox target={businessCount?.tours_organized || 0} desc="Tours Organized" />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default WhyKesariSelect;
