import { Grid } from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

// const CustomFormControlLabel = styled(FormControlLabel)({
//   fontFamily: 'Gotham, sans-serif',
//   fontSize: '22px',
//   fontWeight: 400,
//   lineHeight: '22px',
//   textAlign: 'left',
//   padding: '10px',
//   borderRadius: '4px'
// });

const TravellingMonthCalender = ({
  selectedMonth,
  dayHeaderFormat,
  dayHeaderClassName,
  selectedDates,
  renderTileContent,
  handleTileClick,
  handleTabChange,
  handleSelectDate,
  handleActiveStartDateChange,
  tileDisabled
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Calendar
        next2Label={null}
        prev2Label={null}
        firstDayOfWeek={0}
        locale="en-US"
        dayHeaderFormat={dayHeaderFormat}
        dayHeaderClassName={dayHeaderClassName}
        value={selectedDates}
        tileContent={renderTileContent}
        onClickDay={(day) => {
          handleTileClick(day);
          handleTabChange(2);
        }}
        defaultView="month"
        selectRange={false}
        activeStartDate={selectedMonth}
        onChange={handleSelectDate}
        className="vacation-custom-calendar"
        sx={{ zIndex: 999 }}
        onActiveStartDateChange={handleActiveStartDateChange}
        tileDisabled={tileDisabled}
      />
    </Grid>
    {/* <Grid item xs={12}>
                <CustomFormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckBox}
                      name="flexibleDeparture"
                      color="primary"
                    />
                  }
                  label="I’m flexible on departure date"
                  sx={{
                    width: '100%',
                    textAlign: 'center'
                  }}
                />
              </Grid> */}
  </Grid>
);

export default TravellingMonthCalender;
