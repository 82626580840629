import React, { useState } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import InrFlag from '../../assest/Images/currencyFlags/inr_flag.svg';
import UsdFlag from '../../assest/Images/currencyFlags/usd_flag.svg';
import EurFlag from '../../assest/Images/currencyFlags/eur_flag.svg';
import AedFlag from '../../assest/Images/currencyFlags/aed_flag.svg';
import AudFlag from '../../assest/Images/currencyFlags/aud_flag.svg';
import GbpFlag from '../../assest/Images/currencyFlags/gbp_flag.svg';
import SgdFlag from '../../assest/Images/currencyFlags/sgd_flag.svg';
import NzdFlag from '../../assest/Images/currencyFlags/nzd_flag.svg';

const CurrencyDropdown = () => {
  const currency = [
    { title: 'INR', flag: InrFlag },
    { title: 'USD', flag: UsdFlag },
    { title: 'EUR', flag: EurFlag },
    { title: 'AED', flag: AedFlag },
    { title: 'AUD', flag: AudFlag },
    { title: 'GBP', flag: GbpFlag },
    { title: 'SGD', flag: SgdFlag },
    { title: 'NZD', flag: NzdFlag }
  ];

  const [selectedCurrency, setSelectedCurrency] = useState(() => {
    const storedCurrency = sessionStorage.getItem('selectedCurrency') || 'INR';
    sessionStorage.setItem('selectedCurrency', storedCurrency);
    return storedCurrency;
  });

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedCurrency(value);
    sessionStorage.setItem('selectedCurrency', value);
  };

  return (
    <FormControl variant="outlined" size="small" sx={{ minWidth: 70 }}>
      <InputLabel
        id="currency-select-label"
        sx={{
          color: 'white',
          fontFamily: 'gothamBook',
          fontSize: '14px',
          '&.Mui-focused': { color: 'white' }
        }}>
        Currency
      </InputLabel>
      <Select
        labelId="currency-select-label"
        value={selectedCurrency}
        onChange={handleChange}
        label="Currency..."
        MenuProps={{
          PaperProps: {
            sx: {
              zIndex: 9999,
              position: 'absolute'
            }
          }
        }}
        sx={{
          fontFamily: 'gothamBook',
          fontSize: '14px',
          color: 'white',
          height: '30px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
          },
          '& .MuiSelect-icon': {
            color: 'white'
          }
        }}
        renderValue={(value) => {
          const selectedCurrencyData = currency.find((curr) => curr.title === value);
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={selectedCurrencyData.flag}
                alt={selectedCurrencyData.title}
                style={{ width: '20px', height: '20px', marginRight: '10px' }}
              />
              <span style={{ fontFamily: 'gothamBook', fontSize: '14px', color: 'white' }}>
                {selectedCurrencyData.title}
              </span>
            </Box>
          );
        }}>
        {currency.map((curr) => (
          <MenuItem
            key={curr.title}
            value={curr.title}
            sx={{
              fontFamily: 'gothamBook',
              fontSize: '14px',
              padding: '5px 10px',
              '&:hover': {
                backgroundColor: '#3f51b5',
                color: 'white'
              }
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={curr.flag}
                alt={curr.title}
                style={{ width: '20px', height: '20px', marginRight: '10px' }}
              />
              {curr.title}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CurrencyDropdown;
