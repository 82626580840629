import HomeIcon from '../assest/common/breadcrumbs/home.svg';

export const photoGalleryBreadcrumbsData = [
  {
    label: <img src={HomeIcon} alt="home" width={16} height={16} />,
    path: '/'
  },
  { label: 'World', path: '/photo-gallery' },
  { label: 'Europe', path: '/photo-gallery' },
  { label: 'Reviews', path: '/photo-galleryg' }
];

export const desktopRows = [{ count: 4 }, { count: 3 }, { count: 4 }, { count: 3 }, { count: 4 }];

export const mobileRows = [
  { count: 2 },
  { count: 2 },
  { count: 1 },
  { count: 2 },
  { count: 2 },
  { count: 1 },
  { count: 2 },
  { count: 2 },
  { count: 1 },
  { count: 1 }
];
