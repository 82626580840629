/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useRef, useState, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetaDataList } from '../Redux/slices/metaDataSlice';
import { META_DATA_SLUG } from '../APIs/metaDataSlug';
import { API_URL, BASE_URL } from '../APIs/endpoint';
import { api } from '../APIs/api';
import { Box, Skeleton } from '@mui/material';
// import Hero from '../components/home/Hero/Hero';
// import Counting from '../components/home/Counting/Counting';
// import SliderComponent from '../components/home/Slider/Slider';
// import TravelLife from '../components/home/TravelLife/TravelLife';
import EveryStep from '../components/home/EveryStep/EveryStep';
import ThemedEscapes from '../components/home/ThemedEscapes/ThemedEscapes';
import PerfectWeekend from '../components/home/PerfectWeekend/PerfectWeekend';
import TailoredAdventures from '../components/home/TailoredAdventure/TailoredAdventures';
// import DiscoverGems from '../components/home/DiscoverGems/DiscoverGems';
import ReadyToBook from '../components/home/ReadyToBook/ReadyToBook';
import ReviewCard from '../components/home/ReviewCommonCard/ReviewCommonCard';
import Header from '../layout/Header/Header';
import HelmetComponent from '../components/MetaDataComponent/Helmet';
import WhyKesariSelect from '../components/home/WhyKesariSelect/WhyKesariSelect';
const HomeSliderComponent = lazy(() => import('../components/home/HomeSlider/HomeSlider'));
// import QuickEnquiryModal from '../components/home/Hero/QuickEnquiryModal';
// import HeroMessage from '../assest/Home/hero_message.png';
import { motion } from 'framer-motion';

const MotionBox = motion.create(Box);

const Home = () => {
  const dispatch = useDispatch();
  const homeMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);
  const [ratingCount, setRatingCount] = useState();
  const [error, setError] = useState(null);
  const [timeLineSliderData, setTimeLineSliderData] = useState();
  // const [businessCount, setBusinessCount] = useState();
  const [whyKesariData, setWhyKesariData] = useState();
  const [hiddenGemsData, setHiddenGemsData] = useState();
  const [reviewData, setReviewData] = useState();
  const [worldWiseData, setWorldWiseData] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [themeData, setThemeData] = useState();
  const [tourData, setTourData] = useState();

  const discoverGemsRef = useRef(null);
  const themedEscapesRef = useRef(null);
  const readyToBookRef = useRef(null);

  const [sectionVisibility, setSectionVisibility] = useState({
    whyKesari: false,
    everyStep: false,
    themedEscapes: false,
    perfectWeekend: false,
    tailoredAdventures: false,
    reviewCard: false,
    readyToBook: false
  });

  const observer = useRef();

  const getRatingCountsApi = async () => {
    setIsLoader(true);
    try {
      const ratingCountsRes = await api.get(`${BASE_URL}${API_URL.RATING_COUNT}`);
      if (ratingCountsRes.status) {
        setRatingCount(ratingCountsRes.data);
      } else {
        setError(ratingCountsRes.message || 'An error occurred');
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoader(false);
    }
  };

  // const getBusinessCountsAPi = async () => {
  //   try {
  //     const businessCountRes = await api.get(`${BASE_URL}${API_URL.BUSINESS_COUNT}`);
  //     setIsLoader(true);
  //     if (businessCountRes.status) {
  //       setBusinessCount(businessCountRes.data);
  //       setIsLoader(false);
  //     } else {
  //       setError(businessCountRes.message || 'An error occurred');
  //       setIsLoader(false);
  //     }
  //   } catch (err) {
  //     setError(err.message || 'An error occurred');
  //     setIsLoader(false);
  //   }
  // };

  const getWhyKesariSelectAPi = async () => {
    try {
      const WhyKesariSelectRes = await api.get(`${BASE_URL}${API_URL.WHY_KESARI_SELECT}`);
      setIsLoader(true);
      if (WhyKesariSelectRes.status) {
        setWhyKesariData(WhyKesariSelectRes.data);
      } else {
        setError(WhyKesariSelectRes.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoader(false);
    }
  };

  const getTimeLineSliderApi = async () => {
    try {
      const timeLineSliderRes = await api.get(`${BASE_URL}${API_URL.TIMELINE_SLIDER}`);
      setIsLoader(true);
      if (timeLineSliderRes.status) {
        setTimeLineSliderData(timeLineSliderRes.data);
        setIsLoader(false);
      } else {
        setError(timeLineSliderRes.message || 'An error occurred');
        setIsLoader(false);
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
      setIsLoader(false);
    }
  };

  const getHiddenGemsApi = async () => {
    try {
      const hiddenGemsRes = await api.get(`${BASE_URL}${API_URL.HIDDEN_GEMS}`);
      setIsLoader(true);
      if (hiddenGemsRes.status) {
        setHiddenGemsData(hiddenGemsRes.data);
        setIsLoader(false);
      } else {
        setError(hiddenGemsRes.message || 'An error occurred');
        setIsLoader(false);
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
      setIsLoader(false);
    }
  };

  const getReviewListApi = async () => {
    try {
      const reviewRes = await api.get(`${BASE_URL}${API_URL.REVIEW_LIST}`);
      setIsLoader(true);
      if (reviewRes.status) {
        setReviewData(reviewRes.data);
        setIsLoader(false);
      } else {
        setError(reviewRes.message || 'An error occurred');
        setIsLoader(false);
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
      setIsLoader(false);
    }
  };

  const getWorldWisePlacesApi = async () => {
    try {
      const worldWisePlacesRes = await api.get(`${BASE_URL}${API_URL.WORLD_WISE_PLACES}`);
      setIsLoader(true);
      if (worldWisePlacesRes.status) {
        setWorldWiseData(worldWisePlacesRes.data);
        setIsLoader(false);
      } else {
        setError(worldWisePlacesRes.message || 'An error occurred');
        setIsLoader(false);
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
      setIsLoader(false);
    }
  };

  const getThemeListApi = async () => {
    try {
      const themeRes = await api.get(`${BASE_URL}${API_URL.THEME_LIST}`);
      setIsLoader(true);
      if (themeRes.status) {
        setThemeData(themeRes.data);
        setIsLoader(false);
      } else {
        setError(themeRes.message || 'An error occurred');
        setIsLoader(false);
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
      setIsLoader(false);
    }
  };

  const getReadyToBookTourListApi = async () => {
    try {
      setIsLoader(true);
      const readyBookTourRes = await api.get(`${BASE_URL}${API_URL.READY_TO_BOOK_TOUR_LIST}`);
      if (readyBookTourRes.status) {
        setTourData(readyBookTourRes.data);
        setIsLoader(false);
      } else {
        setError(readyBookTourRes.message || 'An error occurred');
        setIsLoader(false);
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
      setIsLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.setItem('tripDetails', JSON.stringify({}));
    dispatch(fetchMetaDataList({ slug: META_DATA_SLUG.HOME_META_SLUG })).finally(() =>
      setIsLoader(false)
    );
    getTimeLineSliderApi();
    getRatingCountsApi();
    // getBusinessCountsAPi();
    getWhyKesariSelectAPi();
    getWorldWisePlacesApi();
    getHiddenGemsApi();
    getReviewListApi();
    getThemeListApi();
    getReadyToBookTourListApi();
  }, [dispatch]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setSectionVisibility((prev) => ({
            ...prev,
            [entry.target.id]: true
          }));
          observer.current.unobserve(entry.target);
        }
      });
    };

    observer.current = new IntersectionObserver(handleIntersect, options);

    const sections = [
      'whyKesari',
      'everyStep',
      'themedEscapes',
      'perfectWeekend',
      'tailoredAdventures',
      'reviewCard',
      'readyToBook'
    ];
    sections.forEach((section) => {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        observer.current.observe(sectionElement);
      }
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  const [openQuickEnquiry, setOpenQuickEnquiry] = useState(false);
  const handleQuickEnquiryOpen = () => setOpenQuickEnquiry(true);
  const handleQuickEnquiryClose = () => setOpenQuickEnquiry(false);

  return (
    <>
      {/* <PageLoadModal open={modalOpen} handleClose={handleModalClose} /> */}
      <HelmetComponent metaData={homeMetaData} />
      <Header
        discoverGemsRef={discoverGemsRef}
        themedEscapesRef={themedEscapesRef}
        readyToBookRef={readyToBookRef}
      />
      <Suspense
        fallback={
          <Box
            className="slider-container"
            sx={{ minHeight: { xs: 156, sm: 335, md: 382, lg: 570 }, content: '""' }}>
            <Skeleton
              variant="rectangular"
              sx={{
                width: { xs: '100%', sm: '100%', md: 1000, lg: 1500, xl: 1500 },
                height: { xs: 156, sm: 335, md: 382, lg: 570 }
              }}
            />
          </Box>
        }>
        <HomeSliderComponent timeLineSliderData={timeLineSliderData} isLoader={isLoader} />
      </Suspense>
      {/* <Hero ratingCount={ratingCount} error={error} /> */}

      <MotionBox
        id="whyKesari"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.whyKesari ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.8 }}>
        <WhyKesariSelect isLoader={isLoader} whyKesariData={whyKesariData} />
      </MotionBox>

      <MotionBox
        id="everyStep"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.everyStep ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.5 }}>
        <EveryStep />
      </MotionBox>

      <MotionBox
        id="themedEscapes"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.themedEscapes ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.5 }}>
        <ThemedEscapes themeData={themeData} isLoader={isLoader} ref={themedEscapesRef} />
      </MotionBox>

      <MotionBox
        id="perfectWeekend"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.perfectWeekend ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.5 }}>
        <PerfectWeekend />
      </MotionBox>

      <MotionBox
        id="tailoredAdventures"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.tailoredAdventures ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.5 }}>
        <TailoredAdventures />
      </MotionBox>

      <MotionBox
        id="reviewCard"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.reviewCard ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.5 }}>
        <ReviewCard isLoader={isLoader} reviewData={reviewData} />
      </MotionBox>

      <MotionBox
        id="readyToBook"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.readyToBook ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.5 }}>
        <ReadyToBook tourData={tourData} isLoader={isLoader} ref={readyToBookRef} />
      </MotionBox>

      {/* <Counting businessCount={businessCount} isLoader={isLoader} /> */}
      {/* <SliderComponent timeLineSliderData={timeLineSliderData} /> */}
      {/* <TravelLife worldWiseData={worldWiseData} isLoader={isLoader} /> */}
      {/* <DiscoverGems hiddenGemsData={hiddenGemsData} isLoader={isLoader} ref={discoverGemsRef} /> */}
      {/* <Box className="hero_message" onClick={handleQuickEnquiryOpen}>
        <img src={HeroMessage} alt="hero_message" className="hero-message-img" />
      </Box>
      <QuickEnquiryModal open={openQuickEnquiry} handleClose={handleQuickEnquiryClose} /> */}
    </>
  );
};

export default Home;
