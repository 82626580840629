import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { AccessTimeFilled, Call, Email, CloseRounded, WhatsApp } from '@mui/icons-material';
import KesariSelectImg from '../../assest/Images/Kesari_Select.png';

const ContactUsModal = ({ open, handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      disablebackdropclick="true"
      fullScreen={isMobile}
      aria-labelledby="contact-us-dialog-title"
      aria-describedby="contact-us-dialog-description"
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : 'auto',
          maxWidth: isMobile ? '100%' : '600px',
          margin: isMobile ? '0' : 'auto',
          marginTop: isMobile ? '90%' : '15%',
          height: isMobile ? 'calc(100% - 20%)' : 'auto',
          maxHeight: isMobile ? 'calc(100% - 40%)' : 'auto'
        }
      }}>
      <DialogTitle id="contact-us-dialog-title">
        <Box className="login-iconAndImageStyles">
          <Box mx="auto" display="flex">
            <img className="imageStyles" src={KesariSelectImg} alt="Image" />
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.grey[500] }}>
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent className="login-modal-ui">
        <Box mb={2} textAlign="center">
          <Typography
            sx={{
              fontFamily: 'gothamBold',
              fontSize: '26px',
              lineHeight: '24px',
              textAlign: 'center'
            }}>
            Contact Us
          </Typography>
        </Box>
        <Box className="box-tab-120">
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 2 }}>
            <Call fontSize="small" sx={{ marginRight: '5px' }} />
            <Typography
              sx={{
                fontFamily: 'inherit',
                fontSize: '16px',
                lineHeight: '18px',
                marginRight: '10px'
              }}>
              Tailormade Holidays:
            </Typography>
            <Typography
              component="a"
              href="tel:18002669080"
              sx={{
                fontFamily: 'gothamBold',
                fontSize: '16px',
                lineHeight: '18px',
                color: 'inherit',
                textDecoration: 'none'
              }}>
              <strong>1800 266 9080</strong>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 1 }}>
            <WhatsApp fontSize="small" sx={{ marginRight: '5px' }} />
            <Typography
              sx={{
                fontFamily: 'inherit',
                fontSize: '16px',
                lineHeight: '18px',
                marginRight: '10px'
              }}>
              WhatsApp no:
            </Typography>
            <Typography
              component="a"
              href="https://wa.me/918657758850"
              target="_blank"
              sx={{
                fontFamily: 'gothamBold',
                fontSize: '16px',
                lineHeight: '18px',
                color: 'inherit',
                textDecoration: 'none'
              }}>
              <strong>+91 86577 58850</strong>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 1 }}>
            <Email fontSize="small" sx={{ marginRight: '5px' }} />
            <Typography
              sx={{
                fontFamily: 'inherit',
                fontSize: '16px',
                lineHeight: '18px',
                marginRight: '10px'
              }}>
              Email:
            </Typography>
            <Typography
              component="a"
              href="mailto:holiday@kesariselect.in"
              sx={{
                fontFamily: 'inherit',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '18px',
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer'
              }}>
              <strong>holiday@kesariselect.in</strong>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 1 }}>
            <AccessTimeFilled fontSize="small" sx={{ marginRight: '5px' }} />
            <Typography
              sx={{
                fontFamily: 'inherit',
                fontSize: '16px',
                lineHeight: '18px',
                marginRight: '10px'
              }}>
              Office Time:
            </Typography>
            <Typography
              sx={{
                fontFamily: 'gothamBold',
                fontSize: '16px',
                lineHeight: '18px'
              }}>
              <strong>10am to 6pm</strong>
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ContactUsModal;
