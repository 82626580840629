import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Divider,
  ImageList,
  ImageListItem,
  Pagination,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetaDataList } from '../../Redux/slices/metaDataSlice';
import { META_DATA_SLUG } from '../../APIs/metaDataSlug';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL, IMAGE_DIR_PATH, PUBLIC_CMS_URL } from '../../APIs/endpoint';
import { getTopDestinationContinent } from '../../Helper/global';
import { desktopRows, mobileRows, photoGalleryBreadcrumbsData } from '../../constants/photoGallery';
import CustomBreadcrumbs from '../../components/BreadCrumbs';
import CustomSelect from '../../components/common/CustomSelect';
import PhotoGalleySkeleton from '../../components/Loader/PhotoGallerySkeleton';
import HelmetComponent from '../../components/MetaDataComponent/Helmet';

const PhotoGallery = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const rows = isTablet ? mobileRows : desktopRows;
  const dispatch = useDispatch();
  const photoGalleryMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);
  const [selectedSortOption, setSelectedSortOption] = useState('newest');
  const [galleryData, setGalleryData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSortChange = (value) => {
    setSelectedSortOption(value);
  };

  const getReviewGalleryApi = async () => {
    try {
      setIsLoader(true);
      const galleryResponse = await api.get(
        `${BASE_URL}${API_URL.GET_REVIEW_GALLERY}?continent=${getTopDestinationContinent}&page=${currentPage}&limit=10&sortby=${selectedSortOption}`
      );
      if (galleryResponse.status) {
        setTotalCount(galleryResponse.total_count);
        setGalleryData(galleryResponse.data);
      } else {
        setError(galleryResponse.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(fetchMetaDataList({ slug: META_DATA_SLUG.PHOTO_GALLERY })).finally(() =>
      setIsLoader(false)
    );
    getReviewGalleryApi();
  }, [currentPage, selectedSortOption, dispatch]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const flattenedGalleryData = galleryData.flatMap((item) =>
    item.review_image.map((image) => ({
      id: image,
      ImageURL: `${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.REVIEW_LIST_PATH}${image}`,
      createdAt: item.created_at.date
    }))
  );

  const renderImagesInRows = () => {
    const totalRows = rows.length;
    let currentIndex = 0;
    const result = [];

    while (currentIndex < flattenedGalleryData.length) {
      for (let i = 0; i < totalRows; i++) {
        const row = rows[i];
        const rowImages = flattenedGalleryData.slice(currentIndex, currentIndex + row.count);
        currentIndex += row.count;

        if (rowImages.length === 0) break;

        result.push(
          <ImageList
            key={`${currentIndex}-${i}`}
            cols={row.count}
            gap={8}
            sx={{
              marginBottom: 2,
              justifyContent: 'space-between',
              display: 'flex',
              height: { xs: 152, sm: 200, md: 260 },
              overflow: 'hidden',
              padding: 0
            }}>
            {rowImages.map((photo) => (
              <ImageListItem
                key={photo.id}
                sx={{
                  height: '100%',
                  width: '100%',
                  overflow: 'hidden',
                  flex: isTablet ? 1 : 'auto'
                }}>
                <img
                  src={photo.ImageURL}
                  alt={photo.title}
                  loading="lazy"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: isTablet && 0
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        );

        if (currentIndex >= flattenedGalleryData.length) break;
      }
    }
    return result;
  };

  return (
    <Box sx={{ width: '100%', height: '100%', pt: '20px', bgcolor: '#F5F5F5' }}>
      <HelmetComponent metaData={photoGalleryMetaData} />
      <Box
        sx={{
          maxWidth: { md: '1194px' },
          mx: 'auto',
          p: isTablet && 2,
          '& .MuiPaginationItem-root': { bgcolor: '#fff' }
        }}>
        <CustomBreadcrumbs breadcrumbsData={photoGalleryBreadcrumbsData} />
        <Box
          mt={{ xs: '28px', md: 5 }}
          display={{ md: 'flex' }}
          justifyContent={{ md: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              textDecoration: 'none',
              flexWrap: 'wrap',
              pb: isTablet && '4px'
            }}>
            <Link
              to="/product-review"
              sx={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                flexWrap: 'wrap'
              }}>
              <Typography
                sx={{
                  textDecoration: 'underline',
                  color: '#20285b',
                  fontSize: 18,
                  fontWeight: 300,
                  lineHeight: isTablet ? '28px' : '26px',
                  fontFamily: 'inherit'
                }}>
                All Testimonials ({totalCount} Reviews)
              </Typography>
            </Link>
            <Divider sx={{ width: 2, bgcolor: '#000', height: '20px' }} />
            {!isTablet && <br />}
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 18,
                textDecoration: 'none !important',
                color: '#000',
                lineHeight: isTablet ? '28px' : '26px'
              }}
              component="span">
              Photo Gallery
            </Typography>
          </Box>
          <CustomSelect
            options={['newest', 'oldest']}
            title="Sort by: Newest to Oldest"
            onChange={handleSortChange}
            selectedOption={selectedSortOption}
            sx={{ mt: { xs: '32px', md: '0' }, bgcolor: '#fff' }}
          />
        </Box>

        <Container disableGutters sx={{ minHeight: galleryData.length === 0 && '50vh' }}>
          {isLoader ? (
            <PhotoGalleySkeleton />
          ) : error ? (
            <Typography>{error}</Typography>
          ) : galleryData.length === 0 ? (
            <Typography
              sx={{
                textAlign: 'center',
                mt: 4,
                mb: 6,
                fontFamily: 'gothamBold',
                fontSize: '20px'
              }}>
              No Data Found!
            </Typography>
          ) : (
            renderImagesInRows()
          )}
        </Container>
        {totalCount > 10 && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination
              count={Math.ceil(totalCount / 10)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              sx={{
                pt: { xs: '25px', md: '71px' },
                pb: { xs: '50px', md: '81px' },
                ml: { md: 'auto' },
                width: 'fit-content'
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PhotoGallery;
