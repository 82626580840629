import React, { useEffect, useState, useRef } from 'react';
import './UserDetailsModal.css';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ChevronLeftRounded, CloseRounded } from '@mui/icons-material';
import { api } from '../../../../APIs/api';
import { API_URL, BASE_URL } from '../../../../APIs/endpoint';
import { toast } from 'react-toastify';
import moment from 'moment';

const UserDetailsModal = ({ open, openCustomizeModal, handleClose, handleOtpModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [citiesData, setCitiesData] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    city: '',
    email: '',
    phoneNumber: ''
  });
  const [errors, setErrors] = useState({
    name: '',
    city: '',
    email: '',
    phoneNumber: ''
  });
  const [isLoader, setIsLoader] = useState(false);
  const nameInputRef = useRef(null);
  const cityInputRef = useRef();

  const getCityListApi = async () => {
    try {
      const searchCityRes = await api.get(
        `${BASE_URL}${API_URL.SEARCH_CITIES}?city=${formValues.city}`
      );
      if (searchCityRes && searchCityRes.status) {
        const convertedCities = searchCityRes.data.map((item) => ({
          label: item.name,
          id: item._id.$oid
        }));
        setCitiesData(convertedCities);
      } else {
        toast.error(searchCityRes.message || 'Failed to fetch cities.', { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      cityInputRef.current.focus();
    }
  };

  useEffect(() => {
    if (formValues.city?.length > 1) {
      getCityListApi();
    }
  }, [formValues.city]);

  useEffect(() => {
    if (open && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [open]);

  const handleBack = () => {
    openCustomizeModal();
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));

    if (id === 'city' && value.length > 1) {
      const filtered = citiesData.filter((city) =>
        city.label.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCities(filtered);
      setIsDropdownOpen(filtered.length > 0);
    } else {
      setFilteredCities([]);
      setIsDropdownOpen(false);
    }
    validateInput(id, value);
  };

  const handleCitySelect = (city) => {
    setFormValues({ ...formValues, city: city.label });
    setIsDropdownOpen(false);
    cityInputRef.current.focus();
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsDropdownOpen(false);
    }
  };

  const validateInput = (field, value) => {
    let error = '';
    switch (field) {
      case 'name':
        if (value.length > 50) {
          error = 'Name cannot exceed 50 characters';
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          error = 'Name only accepts letters and spaces';
        }
        break;
      case 'city':
        if (value.length > 50) {
          error = 'City cannot exceed 50 characters';
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          error = 'City only accepts letters and spaces';
        }
        break;
      case 'email': {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (value.length > 50) {
          error = 'Email cannot exceed 50 characters';
        } else if (!emailRegex.test(value)) {
          error = 'Please enter a valid email address';
        }
        break;
      }
      case 'phoneNumber':
        if (!/^(\+?\d{1,15})$/.test(value)) {
          error =
            'Phone number must be between 10 and 15 characters and only contain numbers and +';
        } else if (value.length < 10 || value.length > 15) {
          error = 'Phone number must be between 10 and 15 characters';
        }
        break;

      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error
    }));
  };

  const isFormValid = () =>
    Object.values(formValues).every((field) => field) &&
    !Object.values(errors).some((error) => error);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) return;
    setIsLoader(true);
    try {
      const existingTripDetails = JSON.parse(sessionStorage.getItem('tripDetails')) || {};
      const updatedTripDetails = {
        ...existingTripDetails,
        userDetails: formValues
      };
      sessionStorage.setItem('tripDetails', JSON.stringify(updatedTripDetails));

      // Create an Enquiry payload
      const enquiryData = {
        name: formValues.name,
        mobile: formValues.phoneNumber,
        email: formValues.email,
        city: formValues.city,
        destination:
          existingTripDetails?.packageId === 'N/A' ? existingTripDetails?.countryName : 'N/A',
        package_id:
          existingTripDetails?.packageId === 'N/A' ? 'N/A' : existingTripDetails?.packageId,
        month: existingTripDetails?.tripMonth,
        date_of_travel:
          existingTripDetails?.tripDate === 'N/A'
            ? 'N/A'
            : moment(existingTripDetails.tripDate).format('YYYY-MM-DD'),
        travelling_with: existingTripDetails?.vacationGroup,
        duration: existingTripDetails?.vacationDays,
        adults: Number(existingTripDetails?.tripMembers?.adults),
        children: Number(existingTripDetails?.tripMembers?.children),
        infants: Number(existingTripDetails?.tripMembers?.infants),
        hotel_rating: Number(existingTripDetails?.tripMembers?.hotelRating),
        include_flights: existingTripDetails?.tripMembers?.includeFlights
      };

      // API integration
      const enquiryRes = await api.post(`${BASE_URL}${API_URL.CREATE_ENQUIRY}`, enquiryData);
      const { status, data } = enquiryRes;
      if (status) {
        toast.success(data, { autoClose: 3000 });
        handleCloseWithReset();
        handleOtpModal();
      } else {
        toast.error(data, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const handleCloseWithReset = () => {
    setFormValues({
      name: '',
      email: '',
      phoneNumber: '',
      city: ''
    });
    setErrors({
      name: '',
      email: '',
      phoneNumber: '',
      city: ''
    });
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        disablebackdropclick="true"
        fullScreen={isMobile}
        aria-labelledby="user-details-dialog-title"
        aria-describedby="user-details-dialog-description"
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : 'auto',
            maxWidth: isMobile ? '100%' : '600px',
            margin: isMobile ? '0' : 'auto',
            marginTop: isMobile ? '40%' : '5%',
            height: isMobile ? 'calc(100% - 20%)' : 'auto',
            maxHeight: isMobile ? 'calc(100% - 20%)' : 'auto'
          }
        }}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <DialogTitle id="userDetailsModal" pb={0}>
            <Box
              className="user-details-title"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <ChevronLeftRounded
                onClick={handleBack}
                sx={{
                  cursor: 'pointer',
                  color: 'inherit'
                }}
              />
              <Typography
                className="user-details-text"
                sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                User Details
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseWithReset}
                sx={{ color: (theme) => theme.palette.grey[500] }}>
                <CloseRounded />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className="user-details-modal-ui" sx={{ paddingBottom: '0px !important' }}>
            <Grid container pb={2} justifyContent="center">
              <Box className="box-tab-1">
                <TextField
                  autoFocus
                  inputRef={nameInputRef}
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.name}
                  onChange={handleInputChange}
                  required
                  inputProps={{ maxLength: 50 }}
                  helperText={errors.name}
                  error={!!errors.name}
                  disabled={isLoader}
                />
                <Box style={{ position: 'relative' }}>
                  <TextField
                    autoComplete="off"
                    margin="dense"
                    id="city"
                    label="City"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="city"
                    value={formValues.city}
                    onChange={handleInputChange}
                    required
                    inputRef={cityInputRef}
                    helperText={errors.city}
                    error={!!errors.city}
                    disabled={isLoader}
                    onBlur={handleBlur}
                  />
                  {isDropdownOpen && filteredCities.length > 0 && (
                    <ul style={dropdownStyles}>
                      {filteredCities.map((city) => (
                        <li
                          key={city.id}
                          onClick={() => handleCitySelect(city)}
                          style={listItemStyles}>
                          {city.label}
                        </li>
                      ))}
                    </ul>
                  )}
                </Box>
                <TextField
                  margin="dense"
                  id="email"
                  label="Email Id"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={formValues.email}
                  onChange={handleInputChange}
                  required
                  inputProps={{ maxLength: 50 }}
                  helperText={errors.email}
                  error={!!errors.email}
                  disabled={isLoader}
                />
                <TextField
                  margin="dense"
                  id="phoneNumber"
                  label="Phone Number"
                  type="tel"
                  fullWidth
                  variant="outlined"
                  value={formValues.phoneNumber}
                  onChange={handleInputChange}
                  required
                  inputProps={{ minLength: 6, maxLength: 15 }}
                  helperText={errors.phoneNumber}
                  error={!!errors.phoneNumber}
                  disabled={isLoader}
                />
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }} pb={3}>
            <Box className="user-details-filed-row">
              <Button
                type="submit"
                variant="contained"
                color="error"
                fullWidth
                disabled={!isFormValid() || isLoader}
                onClick={(e) => {
                  if (!isLoader) {
                    handleSubmit(e);
                  }
                }}
                sx={{
                  backgroundColor: isFormValid() ? '#d32f2f' : '#d32f2f',
                  color: isFormValid() ? '#fff' : '#fff',
                  '&:disabled': {
                    backgroundColor: '#d32f2f',
                    color: '#fff',
                    opacity: 0.5
                  }
                }}>
                {isLoader ? (
                  <CircularProgress size={24} style={{ color: '#1a90ff' }} />
                ) : (
                  'Submit Details'
                )}
                <Box style={{ display: 'flex', alignItems: 'center' }} ml={2}>
                  <svg
                    width="13"
                    height="22"
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
                      fill={isLoader ? '#1a90ff' : 'url(#paint0_linear_1152_47198)'}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1152_47198"
                        x1="0.464783"
                        y1="11"
                        x2="12.9648"
                        y2="11"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <svg
                    width="13"
                    height="22"
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
                      fill={isLoader ? '#1a90ff' : 'url(#paint0_linear_1152_47198)'}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1152_47198"
                        x1="0.464783"
                        y1="11"
                        x2="12.9648"
                        y2="11"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </Box>
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

// Styles for dropdown and list items
const dropdownStyles = {
  border: '1px solid #ccc',
  maxHeight: '150px',
  overflowY: 'auto',
  position: 'absolute',
  background: 'white',
  width: '100%',
  zIndex: 1000,
  marginTop: '5px',
  padding: 0
};

const listItemStyles = {
  padding: '10px',
  cursor: 'pointer',
  borderBottom: '1px solid #ddd',
  listStyleType: 'none'
};

export default UserDetailsModal;
