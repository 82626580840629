import React from 'react';
import { Box, Button } from '@mui/material';
import BtnIcon from '../../../assest/common/btn_icon.svg';
import './PackageCustomizeTourButton.css';
import { useNavigate } from 'react-router-dom';

const PackageCustomizeTourButton = ({ packageDetails }) => {
  const navigate = useNavigate();

  const handleEnquiryNow = () => {
    const countryName =
      packageDetails?.country === 'India' ? packageDetails?.state : packageDetails?.country;
    const packageId = packageDetails?.new_item_code;
    sessionStorage.setItem(
      'tripDetails',
      JSON.stringify({
        countryName: countryName,
        packageId: packageId
      })
    );
    navigate(`/vacation-spot`, {
      state: {
        packageId: packageId,
        selectedTour: countryName
      }
    });
  };

  return (
    <Button className="customize-tour-footer-btn" variant="contained" onClick={handleEnquiryNow}>
      <Box className="btn-with-text">
        <Box className="btn-text">Enquire Now</Box>
        <img src={BtnIcon} alt="" />
      </Box>
    </Button>
  );
};

export default PackageCustomizeTourButton;
