import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';

const CheckBoxDropdown = ({
  title,
  options = [],
  selectedValues = {},
  handleRadioChange,
  handleCheckboxChange,
  isOpen,
  handleToggle,
  isCheckbox
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [showAll, setShowAll] = useState(false);

  const selectedValue =
    Object.keys(selectedValues[title] || {}).find((key) => selectedValues[title][key]) || '';

  const itemsToShow = showAll ? options : options.slice(0, 5);

  return (
    <Box py="22px" px={{ md: '35px' }}>
      <Typography
        onClick={handleToggle}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Typography fontSize={16} fontFamily="gothamBold" lineHeight="26px" color="#000000D9">
          {title}
        </Typography>
        {!isOpen ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
      </Typography>
      <Collapse in={isOpen}>
        <Box
          sx={{
            pt: '10px',
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? 'repeat(1, 1fr)' : 'repeat(1, 1fr)'
          }}>
          {isCheckbox ? (
            itemsToShow?.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    className="Hiren"
                    checked={selectedValues[title]?.[option.value] || false}
                    onChange={(event) => handleCheckboxChange(event, title)}
                    name={option.value}
                    sx={{
                      color: 'd9d9d9',
                      '&.Mui-checked': {
                        color: '#27306B'
                      }
                    }}
                  />
                }
                label={<span style={{ fontFamily: 'poppinsRegular' }}>{option.label}</span>}
              />
            ))
          ) : (
            <FormControl component="fieldset">
              <RadioGroup
                className="Hiren"
                aria-label={title}
                name={title}
                value={selectedValue}
                onChange={(event) => handleRadioChange(event, title)}>
                {itemsToShow?.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={
                      <Checkbox
                        checked={selectedValue === option.value}
                        onChange={(event) => handleRadioChange(event, title)}
                        name={option.value}
                        sx={{
                          color: 'd9d9d9',
                          '&.Mui-checked': {
                            color: '#27306B'
                          }
                        }}
                      />
                    }
                    label={<span style={{ fontFamily: 'poppinsRegular' }}>{option.label}</span>}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </Box>
        {options.length > 5 && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px' }}>
            <Button
              onClick={() => setShowAll((prev) => !prev)}
              sx={{
                fontFamily: 'poppinsRegular',
                textTransform: 'capitalize',
                color: '#E41E25',
                fontSize: '14px',
                backgroundColor: 'transparent',
                border: '1px solid #E41E25',
                borderRadius: '6px'
              }}>
              {showAll ? 'Show Less' : 'Show More'}
            </Button>
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

export default CheckBoxDropdown;
