import React from 'react';
import './PlacesWantToExplore.css';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { ChevronLeftRounded, CloseRounded } from '@mui/icons-material';
import PleaseToEx from '../../../../assest/Images/placeToEx.png';

const placesToExplore = [
  { place: 'Ubud, Indonesia', tagLine: 'The Cultural Homeland', image: PleaseToEx },
  { place: 'Ubud, Indonesia', tagLine: 'The Cultural Homeland', image: PleaseToEx },
  { place: 'Ubud, Indonesia', tagLine: 'The Cultural Homeland', image: PleaseToEx },
  { place: 'Ubud, Indonesia', tagLine: 'The Cultural Homeland', image: PleaseToEx },
  { place: 'Ubud, Indonesia', tagLine: 'The Cultural Homeland', image: PleaseToEx },
  { place: 'Ubud, Indonesia', tagLine: 'The Cultural Homeland', image: PleaseToEx },
  { place: 'Ubud, Indonesia', tagLine: 'The Cultural Homeland', image: PleaseToEx },
  { place: 'Ubud, Indonesia', tagLine: 'The Cultural Homeland', image: PleaseToEx }
];

const PlacesWantToExplore = ({ open, handleClose }) => {
  const handleSubmit = () => {
    handleClose(); // Close the dialog after successful submission
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        disablebackdropclick="true"
        aria-labelledby="place-explore-dialog-title"
        aria-describedby="place-explore-dialog-description">
        <form className="explore-form" onSubmit={handleSubmit}>
          <DialogTitle id="placeExplorreModal" pb={0}>
            <Box
              className="explore-title"
              sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
              <ChevronLeftRounded
                onClick={handleClose}
                sx={{ cursor: 'pointer', color: 'inherit' }}
              />
              <Typography
                className="explore-text"
                display="flex"
                flexDirection="column"
                sx={{ textAlign: ' center' }}>
                Places you want to explore.
              </Typography>
              <IconButton
                aria-label="close"
                sx={{ color: (theme) => theme.palette.grey[500], visibility: 'hidden' }}>
                <CloseRounded />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent
            className="explore-modal-ui"
            sx={{ paddingBottom: '0px !important', mx: 'auto', maxHeight: 300, overflowY: 'auto' }}>
            <Grid container justifyContent="center" gap={1}>
              {placesToExplore.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item className="place-details" display="flex" key={index}>
                    <img className="place-img" src={item.image} alt="place" />
                    <Typography
                      className="place-name place-title"
                      display="flex"
                      flexDirection="column"
                      ml={1}>
                      {item.place}
                      <Typography className="place-span" component="span">
                        {item.tagLine}
                      </Typography>
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{ textAlign: 'right', color: (theme) => theme.palette.grey[500] }}>
                      <CloseRounded />
                    </IconButton>
                  </Grid>
                  <hr className="divider-line" />
                </React.Fragment>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', marginTop: '10px' }} pb={3}>
            <Box className="explore-filed-row">
              <Button
                type="submit"
                color="error"
                variant="contained"
                fullWidth
                sx={{ textTransform: 'none' }}>
                Submit
                <Box style={{ display: 'flex', alignItems: 'center' }} ml={2}>
                  <svg
                    width="13"
                    height="22"
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
                      fill="url(#paint0_linear_1152_47198)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1152_47198"
                        x1="0.464783"
                        y1="11"
                        x2="12.9648"
                        y2="11"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <svg
                    width="13"
                    height="22"
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
                      fill="url(#paint0_linear_1152_47198)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1152_47198"
                        x1="0.464783"
                        y1="11"
                        x2="12.9648"
                        y2="11"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </Box>
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default PlacesWantToExplore;
