import React, { useState } from 'react';
import './PackageTabs.css';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import PackageTabsCard from '../package_tabs_card/PackageTabsCard';
import CustomTabsData from '../package_tabs_card/CustomTabsData';
// import ReviewCard from '../../home/ReviewCard/ReviewCard';

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const PackageTabs = ({ packageDetails, isLoader }) => {
  const [value, setValue] = useState(0);
  const tourItiernaryData = packageDetails?.tour_itiernary;
  const tourHighLights = packageDetails?.highlights;
  const packageInclusionData = packageDetails?.inclusion;
  const packageExclusionData = packageDetails?.exclusion;
  const policyAndTermsData = packageDetails?.policy_and_terms;
  // const reviewsData = packageDetails && packageDetails.reviews;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        variant="scrollable"
        onChange={handleChange}
        scrollButtons="auto"
        allowScrollButtonsMobile
        aria-label="basic tabs example"
        className="customtabs">
        <Tab label="Tour Itiernary" {...a11yProps(0)} />
        {/* <Tab label="Track your Route" {...a11yProps(1)} /> */}
        <Tab label="Inclusion & Exclusions" {...a11yProps(2)} />
        <Tab label="Policy & Terms" {...a11yProps(3)} />
        {/* <Tab label="Reviews" {...a11yProps(4)} /> */}
      </Tabs>
      <CustomTabPanel className="tabpanel" value={value} index={0}>
        <Box className="card-list">
          <PackageTabsCard
            tourItiernaryData={tourItiernaryData}
            tourHighLights={tourHighLights}
            isLoader={isLoader}
          />
        </Box>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        Coming Soon...!!!
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={1}>
        <CustomTabsData
          packageInclusionData={packageInclusionData}
          packageExclusionData={packageExclusionData}
          isLoader={isLoader}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <CustomTabsData policyAndTermsData={policyAndTermsData} isLoader={isLoader} />
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={4}>
        <Grid
          container
          spacing={2}
          mt={{ xs: 1, md: 2 }}
          justifyContent={reviewsData && reviewsData.length === 0 ? 'center' : ''}>
          {reviewsData && reviewsData.length > 0 ? (
            reviewsData.map((review) => (
              <Grid key={review.id} container item xs={12} sm={12} md={12} lg={6} xl={4}>
                <ReviewCard review={review} packageName={packageDetails?.package_name} />
              </Grid>
            ))
          ) : (
            <Box textAlign="center">
              <Typography variant="h5" className="review-no-data-found-text">
                No Data Found!
              </Typography>
            </Box>
          )}
        </Grid>
      </CustomTabPanel> */}
    </>
  );
};

export default PackageTabs;
