import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { CalendarMonth, PlaceRounded, PublicSharp } from '@mui/icons-material';
import CarouselHeader from '../../pages/PackageListing/CarouselHeader';
import { capitalizeFirstLetter, formatPrice } from '../../Helper/global';

const PackageCard = ({ mode, tourPackage, isLoader, selectedCurrency }) => {
  const navigate = useNavigate();
  const [showMorePlaces, setShowMorePlaces] = useState(false);

  const handleShowMorePlaces = () => setShowMorePlaces(true);

  const places = [...tourPackage.places].sort((a, b) => a.sequence_number - b.sequence_number);
  const placesToShow = showMorePlaces ? places : places.slice(0, 2);
  const placesCount = places.length;
  const extraCities = placesCount > 2 ? `+${placesCount - 2} Cities` : null;

  const handleEnquiryNow = () => {
    const countryName =
      tourPackage?.country === 'India' ? tourPackage?.state : tourPackage?.country;
    const packageId = tourPackage?.new_item_code;
    sessionStorage.setItem(
      'tripDetails',
      JSON.stringify({
        countryName: countryName,
        packageId: packageId
      })
    );
    navigate(`/vacation-spot`, {
      state: {
        packageId: packageId,
        selectedTour: countryName
      }
    });
  };

  return (
    <Box
      width={{ xs: '100%', md: mode === 'list' ? '420px' : '100%' }}
      boxShadow="10px 10px 30px 0px #37444A29"
      backgroundColor="#FFFFFF"
      borderRadius="10px"
      display={mode === 'grid' && { md: 'flex' }}
      overflow="hidden">
      <Box
        width={{ xs: '100%', md: mode === 'list' ? 420 : 424 }}
        height={mode === 'list' ? 170 : 290}
        position="relative">
        <CarouselHeader mode={mode} tourPackage={tourPackage} places={places} isLoader={isLoader} />
      </Box>
      <Box display={mode === 'list' && 'grid'}>
        <Box px={{ xs: 2.5, lg: '25px' }} pb={{ xs: 2.5, lg: 3 }} pt={{ xs: '18px', md: '13px' }}>
          <Typography
            fontFamily="gothamBold"
            fontSize={{ xs: 14, md: 20 }}
            lineHeight={{ xs: '22px', md: '32px' }}>
            {isLoader ? (
              <Skeleton variant="text" width="80%" height={30} />
            ) : (
              <>
                {tourPackage?.package_name.length > 33
                  ? tourPackage?.package_name.slice(0, 33) + '...'
                  : tourPackage?.package_name}
              </>
            )}
          </Typography>
          {/* <Typography fontFamily="gothamBook" fontSize="12px" lineHeight="16px" pt={1}>
            {isLoader ? (
              <Skeleton variant="text" width="100%" height={30} />
            ) : (
              <> {tourPackage.description || ''}</>
            )}
          </Typography> */}
          {isLoader ? (
            <Skeleton variant="text" width="80%" height={30} />
          ) : (
            <>
              <Box display="flex" alignItems="center" gap="30px" pt="22px">
                <Box display="flex" alignItems="center" gap="6px">
                  <CalendarMonth fontSize="small" />
                  <Typography
                    component="span"
                    fontFamily="gothamBold"
                    fontSize="14px"
                    lineHeight="16px">
                    {tourPackage?.number_of_days || 0}
                  </Typography>
                  <Typography
                    component="span"
                    fontFamily="gothamBook"
                    fontSize="14px"
                    lineHeight="16px">
                    Days
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="6px">
                  <PublicSharp fontSize="small" />
                  <Typography
                    component="span"
                    fontFamily="gothamBold"
                    fontSize="14px"
                    lineHeight="16px">
                    1
                  </Typography>
                  <Typography
                    component="span"
                    fontFamily="gothamBook"
                    fontSize="14px"
                    lineHeight="16px">
                    Country
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="6px">
                  <PlaceRounded fontSize="small" />
                  <Typography
                    component="span"
                    fontFamily="gothamBold"
                    fontSize="14px"
                    lineHeight="16px">
                    {placesCount}
                  </Typography>
                  <Typography
                    component="span"
                    fontFamily="gothamBook"
                    fontSize="14px"
                    lineHeight="16px">
                    Cities
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box bgcolor="#f9f9fb" padding={3} minWidth="168px">
          {isLoader ? (
            <Skeleton variant="text" width="100%" height={30} />
          ) : (
            <>
              <Box display="flex" flexWrap="wrap" alignItems="center" color="#000000CC" gap="6px">
                {placesToShow.map((place, i) => (
                  <Box key={i}>
                    <Typography
                      component="span"
                      fontFamily="gothamBook"
                      fontSize="13px"
                      lineHeight="24px">
                      {capitalizeFirstLetter(place.Place)}
                    </Typography>
                    <Typography
                      component="span"
                      fontFamily="gothamBold"
                      fontSize="13px"
                      lineHeight="24px"
                      ml={0.5}>
                      ({place?.['Number of Nights']}N)
                    </Typography>
                    {i < placesToShow.length - 1 && placesToShow.length > 1 && (
                      <Typography
                        component="span"
                        fontFamily="gothamBook"
                        fontSize="13px"
                        lineHeight="24px"
                        ml={0.5}>
                        ---
                      </Typography>
                    )}
                  </Box>
                ))}
                {extraCities && !showMorePlaces && (
                  <Typography
                    component="span"
                    fontFamily="gothamBold"
                    fontSize="13px"
                    lineHeight="24px"
                    color="#27306B"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleShowMorePlaces}>
                    --- {extraCities}
                  </Typography>
                )}
                {/* // ) : (
                //   <Box minWidth="0px" />
                // )} */}
              </Box>
            </>
          )}
          <Box pt={{ xs: '18px', md: '13px' }}>
            <Typography
              fontFamily="gothamBook"
              fontSize={14}
              lineHeight={{ xs: '22px', md: '32px' }}>
              Starting From
            </Typography>
            <Typography
              display="flex"
              fontFamily="gothamBold"
              fontSize={{ xs: 14, md: 20 }}
              lineHeight={{ xs: '22px', md: '32px' }}
              sx={{ color: '#27306B' }}>
              {isLoader ? (
                <Skeleton variant="text" width="80%" height={30} />
              ) : (
                <>
                  {(() => {
                    const currencyMapping = {
                      INR: 'inr_price',
                      USD: 'usd_price',
                      AED: 'aed_price',
                      AUD: 'aud_price',
                      EUR: 'eur_price',
                      GBP: 'gbp_price',
                      SGD: 'sgd_price',
                      NZD: 'nzd_price'
                    };

                    const selectedPrice =
                      tourPackage?.[currencyMapping[selectedCurrency]]?.toFixed(0) ||
                      tourPackage?.inr_price?.toFixed(0);
                    const currencySymbol = selectedCurrency || 'INR';
                    return `${currencySymbol} ${formatPrice(selectedPrice)}/-`;
                  })()}
                </>
              )}
              <Typography
                pl={1}
                fontFamily="gothamBook"
                fontSize={14}
                lineHeight={{ xs: '22px', md: '32px' }}
                sx={{ color: '#000000' }}>
                Per Person
              </Typography>
            </Typography>
          </Box>

          <Box
            className="btn-container"
            gap="10px"
            mt={extraCities === null ? '20px' : '20px'}
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            justifyContent="space-between"
            width="100%">
            <Button
              variant="outlined"
              onClick={handleEnquiryNow}
              sx={{
                bgcolor: 'transparent',
                color: '#000000B2',
                border: '1px solid #0000004D',
                borderRadius: '6px',
                fontFamily: 'gothamBook',
                fontSize: '14px',
                lineHeight: '28px',
                padding: '11px 20px',
                textAlign: 'center',
                textTransform: 'capitalize',
                maxHeight: '46px',
                width: {
                  xs: '48%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                  xl: '50%'
                },
                ':hover': {
                  color: '#000000B2',
                  bgcolor: 'transparent',
                  border: '1px solid #0000004D'
                }
              }}>
              Enquire Now
            </Button>
            <Button
              onClick={() =>
                navigate(`/explore/product-details/${tourPackage?.new_item_code}`, {
                  state: { packageId: tourPackage?.new_item_code }
                })
              }
              sx={{
                bgcolor: '#E41E25',
                color: '#fff',
                borderRadius: '6px',
                fontSize: '14px',
                fontFamily: 'gothamBold',
                lineHeight: '28px',
                padding: '11px 20px',
                textAlign: 'center',
                textTransform: 'capitalize',
                maxHeight: '46px',
                width: {
                  xs: '45%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                  xl: '50%'
                },
                ':hover': {
                  color: '#fff',
                  bgcolor: '#E41E25'
                }
              }}>
              View Details
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PackageCard;
