import React from 'react';
import { Box, Tooltip } from '@mui/material';
import facebookLogo from '../../assest/Home/FacebookLogo.svg';
// import linkdinLogo from '../../assest/Home/LinkdinLogo.svg';
import instagramLogo from '../../assest/Home/InstagramLogo.svg';
// import XLogo from '../../assest/Home/Footer/xLog.svg';
import Youtube from '../../assest/Home/Footer/Youtube.svg';
// import Pinterest from '../../assest/Home/Footer/Pinterest.svg';

const SocialMedia = () => {
  const handleFacebookClick = () => {
    window.open('https://www.facebook.com/kesariselect/', '_blank');
  };

  const handleInstaClick = () => {
    window.open('https://www.instagram.com/kesariselect/', '_blank');
  };

  const handleYoutubeClick = () => {
    window.open('https://www.youtube.com/@kesariselect', '_blank');
  };

  // const handleLinkedInClick = () => {
  //   window.open('https://www.linkedin.com/company/kesari-select/', '_blank');
  // };

  // const handleTwitterClick = () => {
  //   window.open('https://x.com/i/flow/login?redirect_after_login=%2Fkesariselect', '_blank');
  // };

  // const handlePinterestClick = () => {
  //   window.open('https://in.pinterest.com/kesariselect/', '_blank');
  // };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        pl: { md: 3 },
        mt: { xs: 3.5, md: 2 }
      }}>
      <Tooltip title="Kesari Select Facebook" arrow>
        <Box
          component="img"
          sx={{
            width: '36px',
            height: '36px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            '&:hover': {
              transform: 'scale(1.2)',
              opacity: 0.9
            }
          }}
          src={facebookLogo}
          onClick={handleFacebookClick}
        />
      </Tooltip>
      <Tooltip title="Kesari Select Instagram" arrow>
        <Box
          component="img"
          sx={{
            width: '36px',
            height: '36px',
            cursor: 'pointer',
            zIndex: '1000',
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            '&:hover': {
              transform: 'scale(1.2)',
              opacity: 0.9
            }
          }}
          src={instagramLogo}
          onClick={handleInstaClick}
        />
      </Tooltip>
      <Tooltip title="Kesari Select Youtube" arrow>
        <Box
          component="img"
          sx={{
            width: '36px',
            height: '36px',
            cursor: 'pointer',
            zIndex: '1000',
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            '&:hover': {
              transform: 'scale(1.2)',
              opacity: 0.9
            }
          }}
          src={Youtube}
          onClick={handleYoutubeClick}
        />
      </Tooltip>
      {/* <Tooltip title="Kesari Select Linkdin" arrow>
        <Box
          component="img"
          sx={{
            width: '36px',
            height: '36px',
            cursor: 'pointer',
            zIndex: '1000',
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            '&:hover': {
              transform: 'scale(1.2)',
              opacity: 0.9
            }
          }}
          src={linkdinLogo}
          onClick={handleLinkedInClick}
        />
      </Tooltip>
      <Tooltip title="Kesari Select Twitter" arrow>
        <Box
          component="img"
          sx={{
            width: '36px',
            height: '36px',
            cursor: 'pointer',
            zIndex: '1000',
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            '&:hover': {
              transform: 'scale(1.2)',
              opacity: 0.9
            }
          }}
          src={XLogo}
          onClick={handleTwitterClick}
        />
      </Tooltip>
      <Tooltip title="Kesari Select Pinterest" arrow>
        <Box
          component="img"
          sx={{
            width: '36px',
            height: '36px',
            cursor: 'pointer',
            zIndex: '1000',
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            '&:hover': {
              transform: 'scale(1.2)',
              opacity: 0.9
            }
          }}
          src={Pinterest}
          onClick={handlePinterestClick}
        />
      </Tooltip> */}
    </Box>
  );
};

export default SocialMedia;
