/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { api } from '../APIs/api';
import { API_URL, BASE_URL } from '../APIs/endpoint';
import ReviewCard from '../components/home/ReviewCommonCard/ReviewCommonCard';
import { Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetaDataList } from '../Redux/slices/metaDataSlice';
import { META_DATA_SLUG } from '../APIs/metaDataSlug';
import PackageBanner from '../components/package_detail/package_banner/PackageBanner';
import PackageDetailsSection2 from '../components/package_detail/package_detials_section2/PackageDetialsSection2';
import PackageCustomizeTourButton from '../components/package_detail/package_customize_tour_button/PackageCustomizeTourButton';
// import RelatedTour from '../components/common/related_tour/RelatedTour';
import HelmetComponent from '../components/MetaDataComponent/Helmet';

const PackageDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedPackageId = location?.state?.packageId;
  const productDetailsMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);
  const [packageDetails, setPackageDetails] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState(null);
  const [reviewData, setReviewData] = useState([]);

  const path = location?.pathname;
  const parts = path.split('/');
  const productId = parts[parts.length - 1];

  const getTourPackageDetailsApi = async () => {
    try {
      setIsLoader(true);
      const detailsResponse = await api.get(
        `${BASE_URL}${API_URL.GET_PACKAGE_DETAILS}?package_id=${selectedPackageId || productId}`
      );
      const { status, data, message } = detailsResponse;
      if (status) {
        if (Object.keys(data)?.length > 0) {
          setPackageDetails(data);
        } else {
          navigate(-1);
        }
      } else {
        setError(message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoader(false);
    }
  };

  const getReviewListApi = async () => {
    try {
      const reviewRes = await api.get(`${BASE_URL}${API_URL.REVIEW_LIST}`);
      setIsLoader(true);
      if (reviewRes.status) {
        setReviewData(reviewRes.data);
      } else {
        setError(reviewRes.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    dispatch(fetchMetaDataList({ slug: META_DATA_SLUG.PRODUCT_DETAILS_SLUG })).finally(() =>
      setIsLoader(false)
    );
    getTourPackageDetailsApi();
    getReviewListApi();
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <HelmetComponent metaData={productDetailsMetaData} />
      {isLoader ? (
        <Skeleton variant="text" width="100%" height={500} />
      ) : (
        <>
          <PackageBanner packageDetails={packageDetails} isLoader={isLoader} />
          <PackageDetailsSection2 packageDetails={packageDetails} isLoader={isLoader} />
          <PackageCustomizeTourButton packageDetails={packageDetails} />
          <ReviewCard reviewData={reviewData} isLoader={isLoader} isDetails={true} />
          {/* <RelatedTour /> */}
        </>
      )}
    </>
  );
};

export default PackageDetails;
