/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './CustomizeTripComponent.css';
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  PeopleAltOutlined,
  Remove,
  Add,
  ApartmentRounded,
  FlightTakeoffOutlined
} from '@mui/icons-material';
import StarVector from '../../../assest/Images/Start_Vector.jpg';

const CustomizeTrip = ({ setTotalPax, handleCustomTripTab }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [formValues, setFormValues] = useState({
    adults: 0,
    infants: 0,
    children: 0,
    hotelRating: '5',
    includeFlights: true
  });

  const handleValueChange = (field, operation) => {
    setFormValues((prevValues) => {
      const newValue = parseInt(prevValues[field], 10) + (operation === 'increment' ? 1 : -1);
      return {
        ...prevValues,
        [field]: Math.max(newValue, 0)
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const existingTripDetails = JSON.parse(sessionStorage.getItem('tripDetails')) || {};
    const updatedTripDetails = {
      ...existingTripDetails,
      tripMembers: formValues
    };
    sessionStorage.setItem('tripDetails', JSON.stringify(updatedTripDetails));
    const totalPax =
      parseInt(formValues.adults) + parseInt(formValues.children) + parseInt(formValues.infants);
    setTotalPax(totalPax);
    handleCustomTripTab();
  };

  return (
    <Box
      sx={{
        maxWidth: isMobile ? '100%' : '400px',
        margin: isMobile ? '0' : 'auto',
        padding: '20px',
        backgroundColor: 'white',
        boxShadow: 3,
        borderRadius: '8px'
      }}>
      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Grid container pb={3} justifyContent="center">
          <Grid className="filed-row" item display="flex" sx={{ color: '#27306B' }}>
            <Icon>
              <PeopleAltOutlined />
            </Icon>
            <Typography className="lable-title" mx={1}>
              No. of members
            </Typography>
          </Grid>
          {['Adults', 'Children', 'Infants'].map((label, index) => (
            <Grid
              key={label}
              className="filed-row"
              item
              display="flex"
              justifyContent="space-between"
              pt={2}
              sx={{ marginBottom: '16px' }}>
              <Typography
                className="lable-title-2nd"
                sx={{ lineHeight: '1.5', paddingRight: '16px' }}>
                {label}
                <Typography
                  className="label-sub-title"
                  sx={{
                    paddingBottom: label === 'Adults' || label === 'Children' ? '8px' : '0px',
                    lineHeight: '1.5'
                  }}>
                  {(() => {
                    let labelText =
                      label === 'Adults'
                        ? 'Above 12 years'
                        : label === 'Children'
                        ? 'Ages 2-12 years'
                        : 'Below 2 years';

                    return labelText.length > 14 ? labelText.slice(0, 14) + '...' : labelText;
                  })()}
                </Typography>
              </Typography>
              <Box className="count-box" sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => handleValueChange(label.toLowerCase(), 'decrement')}
                  disabled={formValues[label.toLowerCase()] === 0}
                  sx={{
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform:
                        !isMobile && formValues[label.toLowerCase()] > 0 ? 'scale(1.2)' : 'none'
                    }
                  }}>
                  <Remove />
                </IconButton>
                <Typography className="count-box-number">
                  {formValues[label.toLowerCase()]}
                </Typography>
                <IconButton
                  onClick={() => handleValueChange(label.toLowerCase(), 'increment')}
                  sx={{
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: !isMobile ? 'scale(1.2)' : 'none'
                    }
                  }}>
                  <Add />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
        <hr className="hr-line" />
        <Grid container justifyContent="center">
          <Grid className="filed-row" item display="flex" sx={{ color: '#27306B' }}>
            <Icon>
              <ApartmentRounded />
            </Icon>
            <Typography className="lable-title" mx={1}>
              Preferred Hotel Rating
            </Typography>
          </Grid>
          <Grid
            className="filed-row"
            item
            display="flex"
            sx={{ color: '#27306B', overflowX: 'auto', overflowY: 'hidden' }}>
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                row
                value={formValues.hotelRating}
                onChange={(e) => setFormValues({ ...formValues, hotelRating: e.target.value })}
                sx={{
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                  width: 'max-content'
                }}>
                {[5, 4, 3, 2, 1].map((rating) => (
                  <FormControlLabel
                    key={rating}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&:hover': {
                            transform: !isMobile ? 'scale(1.2)' : 'none'
                          },
                          '&.Mui-checked:hover': {
                            color: !isMobile ? '#FF5722' : 'inherit'
                          }
                        }}
                      />
                    }
                    label={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          transition: 'transform 0.2s',
                          transform: !isMobile ? 'scale(1)' : 'none',
                          '&:hover': {
                            transform: !isMobile ? 'scale(1.2)' : 'none'
                          }
                        }}>
                        <span
                          className="rating-text"
                          style={{
                            fontSize: '14px',
                            marginRight: '4px',
                            transition: 'transform 0.2s',
                            transform: !isMobile ? 'scale(1)' : 'none'
                          }}>
                          {rating}
                        </span>
                        <img
                          src={StarVector}
                          alt="star"
                          style={{
                            width: '16px',
                            height: '16px',
                            transition: 'transform 0.2s',
                            transform: !isMobile ? 'scale(1)' : 'none'
                          }}
                        />
                      </div>
                    }
                    value={rating.toString()}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <hr className="hr-line" />
        <Grid container justifyContent="center" mb={2}>
          <Grid className="filed-row" item display="flex" sx={{ color: '#27306B' }}>
            <Icon>
              <FlightTakeoffOutlined />
            </Icon>
            <Typography className="lable-title" mx={1}>
              Include Flights
            </Typography>
          </Grid>
          <Grid className="filed-row" item display="flex" sx={{ color: '#27306B' }}>
            <FormControlLabel
              control={
                <Radio
                  checked={formValues.includeFlights}
                  onChange={() => setFormValues({ ...formValues, includeFlights: true })}
                  sx={{
                    '&:hover': {
                      transform: !isMobile ? 'scale(1.2)' : 'none'
                    },
                    '&.Mui-checked:hover': {
                      color: !isMobile ? '#FF5722' : 'inherit'
                    }
                  }}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={!formValues.includeFlights}
                  onChange={() => setFormValues({ ...formValues, includeFlights: false })}
                  sx={{
                    '&:hover': {
                      transform: !isMobile ? 'scale(1.2)' : 'none'
                    },
                    '&.Mui-checked:hover': {
                      color: !isMobile ? '#FF5722' : 'inherit'
                    }
                  }}
                />
              }
              label="No"
            />
          </Grid>
        </Grid>
        <Box className="filed-row">
          <Button
            type="submit"
            variant="contained"
            color="error"
            fullWidth
            disabled={formValues.adults === 0 || formValues.adults === '0'}
            sx={{
              backgroundColor: '#d32f2f',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#c62828'
              },
              '&:disabled': {
                backgroundColor: '#d32f2f',
                color: '#fff',
                opacity: 0.5
              }
            }}>
            Continue
            <Box style={{ display: 'flex', alignItems: 'center' }} ml={2}>
              <svg
                width="13"
                height="22"
                viewBox="0 0 13 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
                  fill="url(#paint0_linear_1152_47198)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1152_47198"
                    x1="0.464783"
                    y1="11"
                    x2="12.9648"
                    y2="11"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                width="13"
                height="22"
                viewBox="0 0 13 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
                  fill="url(#paint0_linear_1152_47198)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1152_47198"
                    x1="0.464783"
                    y1="11"
                    x2="12.9648"
                    y2="11"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </Box>
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default CustomizeTrip;
