import React from 'react';
import BackArrowIcon from '../../../assest/common/back_arrow.svg';
import { Grid, IconButton, Typography } from '@mui/material';
import './ReviewDetailsSection1.css';
import SelectDepartureCityDate from '../select_departure_city_date/SelectDepartureCityDate';
import ReviewAddGuest from '../review_add_guest/ReviewAddGuest';
import SelectAccomodation from '../select_accomodation/SelectAccomodation';
import WhyKesariSelect from '../why_kesari_select/WhyKesariSelect';
import ReviewCollapsePanel from '../review_collapse_panel/ReviewCollapsePanel';
import KesariSelectReview from '../../../components/home/KesariSelectReview/KesariSelectReview';
import BookingDetailsCard from '../booking_details_card/BookingDetailsCard';

const ReviewDetailsSection1 = () => (
  <div className="review-details-section-container">
    <Grid container spacing={2} className="pl-pr-120 review-details-sec-1">
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="d-flex align-items-center review-page-title-sec">
          <IconButton className="icon" aria-label="goback" color="secondary">
            <img src={BackArrowIcon} alt="" />
          </IconButton>
          <div className="page-title-group">
            <Typography variant="h1" className="title">
              Review Your Details
            </Typography>
            <div className="description">You can review your tour details & make payment.</div>
          </div>
        </div>
      </Grid>
    </Grid>
    <Grid container spacing={2} className="two-column-design pl-pr-120">
      <Grid container item xs={12} sm={12} md={7} lg={8} xl={9}>
        <div className="left-part component-list">
          <div className="select-departure-part component-list-item">
            <SelectDepartureCityDate />
          </div>
          <div className="review-add-guest-part component-list-item">
            <ReviewAddGuest />
          </div>
          <div className="select-accomodation-part component-list-item">
            <SelectAccomodation />
          </div>
          <div className="why-kesari-select-part component-list-item">
            <WhyKesariSelect />
          </div>
          <div className="faqs-part component-list-item">
            <ReviewCollapsePanel />
          </div>
        </div>
      </Grid>
      <Grid container item xs={12} sm={12} md={5} lg={4} xl={3}>
        <div className="right-part">
          <BookingDetailsCard />
        </div>
      </Grid>
    </Grid>
    <div className="kesari-select-review-container">
      <Grid container spacing={2} className="two-column-design pl-pr-120">
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="kesari-select-review-part component-list-item">
            <KesariSelectReview />
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default ReviewDetailsSection1;
