import React from 'react';
import { Chip } from '@mui/material';
import { styled } from '@mui/system';
import ActiveTabIcon from '../../assest/Home/active-icon.png';

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#27306B',
  color: '#FFFFFF',
  width: '120px',
  height: '24px',
  padding: '10px 20px',
  borderRadius: '4px',
  fontFamily: 'gothamMedium',
  fontSize: '12px',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: '#303F9F'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '8px 16px'
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const SelectChip = () => (
  <StyledChip
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
      opacity: 0,
      transition: '0.3s all ease-out'
    }}
    label="SELECT"
    icon={
      <img
        src={ActiveTabIcon}
        alt="Active Tab Icon"
        style={{
          marginLeft: '2px',
          width: '12px',
          height: '10px',
          transform: 'rotate(90deg)'
        }}
      />
    }
  />
);

export default SelectChip;
