import React, { useEffect, useState } from 'react';
import './PerfectWeekend.css';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Tab, Typography } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { MonthTabList } from '../../../Helper/global';
import Slider from 'react-slick';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Indonesia_kesari from '../../../assest/Home/Slider/Indonesia_kesari.png';
import kerala_kesari from '../../../assest/Home/Slider/kerala_kesari.jpg';
import japan_kesari from '../../../assest/Home/Slider/japan_kesari.jpg';
import leh_ladakh_kesari from '../../../assest/Home/Slider/leh_ladakh_kesari.jpg';
import CustomButton from '../../common/Button';

const PerfectWeekend = () => {
  const navigate = useNavigate();
  const now = new Date();
  const monthNumber = now.getMonth() + 1;
  const [value, setValue] = useState(monthNumber.toString());
  const [selectedDates, setSelectedDates] = useState([]);
  const [activeDate, setActiveDate] = useState(
    new Date(new Date().getFullYear(), monthNumber - 1, 1)
  );
  const getCurrentYearDate = (month) => {
    const currentYear = new Date().getFullYear();
    const date = new Date(currentYear, month - 1, 1);
    return date;
  };

  useEffect(() => {
    setActiveDate(getCurrentYearDate(Number(value)));
  }, [value]);

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setActiveDate(activeStartDate);
  };

  const dayHeaderFormat = (date) =>
    date.toLocaleDateString('en-US', {
      weekday: 'narrow'
    });
  const dayHeaderClassName = (date) => {
    if (date.getDay() === 0) {
      return 'sunday';
    } else if (date.getDay() === 6) {
      return 'saturday';
    } else {
      return 'weekday';
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const handleSelect = (value) => {
    setSelectedDates(value);
  };
  return (
    <Box className="PerfectWeekend_main">
      <Box className="PerfectWeekend_heading">
        <Box sx={{ height: '100%' }}>
          <Grid container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Box className="PerfectWeekend_heading_title">
                <Typography>Select the Perfect Weekend for Your Dream Destination!</Typography>
              </Box>
              <Typography className="PerfectWeekend_heading_desc">
                Uncertain where to go next? Plan your current journey and future adventures with
                ease. Your year-round passport awaits.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="PerfectWeekend_map_navigation">
        <Box
          sx={{
            width: '100%',
            typography: 'body1'
          }}>
          <Container>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1) !important',
                  display: 'flex',
                  justifyContent: 'center',
                  width: { xs: '100%' },
                  overflowX: { xs: 'auto' }
                }}>
                <TabList
                  orientation="horizontal"
                  variant="scrollable"
                  onChange={handleChange}
                  aria-label="lab API tabs">
                  {MonthTabList.map((item, idx) => (
                    <Tab
                      key={idx}
                      label={item.title}
                      value={item.value}
                      sx={{
                        textTransform: 'none',
                        color: 'rgba(255, 255, 255, 0.6)',
                        '&.Mui-selected': {
                          color: 'white'
                        },
                        '&:hover': {
                          color: 'white'
                        }
                      }}
                    />
                  ))}
                </TabList>
              </Box>
              <Box>
                <Box
                  sx={{
                    marginTop: '42px'
                  }}>
                  <Grid container display={'flex'} justifyContent={'center'}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className="calender_view"
                      sx={{
                        display: {
                          xs: 'flex',
                          md: 'flex',
                          lg: 'flex'
                        },
                        flexDirection: {
                          xs: 'column',
                          md: 'row',
                          lg: 'row'
                        }
                      }}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          overflow: 'hidden !important',
                          lineHeight: '0 !important'
                        }}>
                        <Slider className="PerfectWeekend_parent_component" {...settings}>
                          {parentSlides.map((item, i) => (
                            <Box key={i} className="PerfectWeekend_img_section">
                              <img
                                style={{ width: '100%', height: '100%' }}
                                src={item.img}
                                alt="Slider_img"
                              />
                            </Box>
                          ))}
                        </Slider>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <Box
                          style={{
                            overflowY: 'auto',
                            width: '100%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '0px'
                          }}>
                          <Calendar
                            next2Label={null}
                            prev2Label={null}
                            className="calendar"
                            tileClassName="calendar__tile"
                            firstDayOfWeek={0}
                            locale="en-US"
                            dayHeaderFormat={dayHeaderFormat}
                            dayHeaderClassName={dayHeaderClassName}
                            selectRange={true}
                            onChange={handleSelect}
                            value={selectedDates}
                            activeStartDate={activeDate}
                            onActiveStartDateChange={handleActiveStartDateChange}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </TabContext>
          </Container>
        </Box>
      </Box>

      <Box
        sx={{
          width: '100%',
          paddingTop: '30px',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Box className="PerfectWeekend_btn_container">
          <CustomButton
            className="PerfectWeekend_typo_btn"
            onClick={() => navigate('/explore')}
            showArrow={true}>
            <Typography fontFamily="gothamBold">Explore Places</Typography>
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

const parentSlides = [
  {
    slide_title: 'INDONESIA',
    slide_desc:
      'Discover the enchanting beauty of Indonesia, a diverse archipelago where lush rainforests, pristine beaches, and vibrant cultures converge, creating unforgettable tropical getaway.',
    img: Indonesia_kesari
  },
  {
    slide_title: 'KERALA',
    slide_desc:
      'Discover the enchanting beauty of Indonesia, a diverse archipelago where lush rainforests, pristine beaches, and vibrant cultures converge, creating unforgettable tropical getaway.',
    img: kerala_kesari
  },
  {
    slide_title: 'JAPAN',
    slide_desc:
      'Discover the enchanting beauty of Indonesia, a diverse archipelago where lush rainforests, pristine beaches, and vibrant cultures converge, creating unforgettable tropical getaway.',
    img: japan_kesari
  },
  {
    slide_title: 'LEH LADAKH',
    slide_desc:
      'Discover the enchanting beauty of Indonesia, a diverse archipelago where lush rainforests, pristine beaches, and vibrant cultures converge, creating unforgettable tropical getaway.',
    img: leh_ladakh_kesari
  }
];
export default PerfectWeekend;
