import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../assest/stylesheet/style.css';
import './ProductReview.css';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import ReviewCard from '../../components/home/ReviewCard/ReviewCard';
import CustomSelect from '../../components/common/CustomSelect';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import ReviewCardSkeleton from '../../components/Loader/ProductReviewSkeleton';
import { getTopDestinationContinent } from '../../Helper/global';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetaDataList } from '../../Redux/slices/metaDataSlice';
import { META_DATA_SLUG } from '../../APIs/metaDataSlug';
import HelmetComponent from '../../components/MetaDataComponent/Helmet';

const ProductReview = () => {
  const dispatch = useDispatch();
  const productReviewMedataData = useSelector((state) => state?.metaDataList?.metaDataList[0]);
  const [selectedSortOption, setSelectedSortOption] = useState('');
  const [reviewData, setReviewData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getReviewListApi = async () => {
    try {
      setIsLoader(true);
      const reviewRes = await api.get(
        `${BASE_URL}${
          API_URL.REVIEW_LIST
        }?continent=${getTopDestinationContinent}&limit=${10}&page=${currentPage}&sortby=${selectedSortOption}`
      );
      if (reviewRes.status) {
        setReviewData(reviewRes.data);
        setTotalCount(reviewRes.total_count);
        setIsLoader(false);
      } else {
        setError(reviewRes.message);
        setIsLoader(false);
      }
    } catch (err) {
      setError(err.message);
      setIsLoader(false);
    }
  };

  const handleSortChange = (value) => {
    setSelectedSortOption(value);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchMetaDataList({ slug: META_DATA_SLUG.PRODUCT_REVIEW_SLUG })).finally(() =>
      setIsLoader(false)
    );
    getReviewListApi();
  }, [currentPage, selectedSortOption]);

  return (
    <Box className="product-review-container cmn-space-section">
      <HelmetComponent metaData={productReviewMedataData} />
      <Box className="toolbar-container">
        <Box className="toolbar-left">
          <Box className="title-with-link">
            <Typography variant="h3">
              {getTopDestinationContinent
                ? `Best of ${getTopDestinationContinent} `
                : 'All Testimonals '}
              ( {totalCount} Reviews )
            </Typography>
            <Link to="/photo-gallery">Photo Gallery</Link>
          </Box>
        </Box>
        <CustomSelect
          title="Sort by: Newest to Oldest"
          options={['newest', 'oldest']}
          selectedOption={selectedSortOption}
          onChange={handleSortChange}
          sx={{ mt: { xs: 4, md: 0 } }}
        />
      </Box>
      <Grid
        container
        spacing={2}
        mt={{ xs: 1, md: 2 }}
        justifyContent={reviewData.length === 0 && isLoader ? 'center' : ''}
        alignItems="center"
        sx={{ minHeight: '80vh' }}>
        {isLoader ? (
          Array.from({ length: 6 }).map((_, index) => <ReviewCardSkeleton key={index} />)
        ) : error ? (
          <Box textAlign="center">
            <Typography variant="h5" className="review-no-data-found-text">
              {error}
            </Typography>
          </Box>
        ) : reviewData.length > 0 ? (
          reviewData.map((review) => (
            <Grid key={review.id} container item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ReviewCard
                review={review}
                continent={getTopDestinationContinent}
                isLoader={isLoader}
                error={error}
              />
            </Grid>
          ))
        ) : (
          <Box textAlign="center" sx={{ width: '100%' }}>
            <Typography className="review-no-data-found-text">No Data Found!</Typography>
          </Box>
        )}
      </Grid>
      {totalCount > 10 && (
        <Grid container spacing={2}>
          <Grid container item>
            <Box className="pagination-container">
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ProductReview;
