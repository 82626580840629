/* eslint-disable no-unused-vars */
import DessertIcon from '../assest/Home/Tailored_Adventures/dessert.png';
import HillStationIcon from '../assest/Home/Tailored_Adventures/hill_station.png';
import TrekkingIcon from '../assest/Home/Tailored_Adventures/trekking.png';
import BeachIcon from '../assest/Home/Tailored_Adventures/Beach.png';
import RiverCruiseIcon from '../assest/Home/Tailored_Adventures/river_cruise.png';
import SafariIcon from '../assest/Home/Tailored_Adventures/Safari.png';
import SufringIcon from '../assest/Home/Tailored_Adventures/Sufring.png';
import SailingIcon from '../assest/Home/Tailored_Adventures/Sailing.png';

export const AdventuresTabMenu = [
  {
    tab_img: DessertIcon,
    tab_txt: 'Desert'
  },
  {
    tab_img: HillStationIcon,
    tab_txt: 'Hill Stations'
  },
  // {
  //   tab_img: TrekkingIcon,
  //   tab_txt: 'Hiking & Trekking'
  // },
  {
    tab_img: BeachIcon,
    tab_txt: 'Beach'
  },
  // {
  //   tab_img: RiverCruiseIcon,
  //   tab_txt: 'River Cruise'
  // },
  {
    tab_img: SafariIcon,
    tab_txt: 'Safari'
  }
  // {
  //   tab_img: SufringIcon,
  //   tab_txt: 'Surfing'
  // },
  // {
  //   tab_img: SailingIcon,
  //   tab_txt: 'Sailing'
  // }
];
