import NorthAmerica from '../assest/Home/northAmericaIcon.PNG';
import SouthAmerica from '../assest/Home/south-america.svg';
import EuropeIcon from '../assest/Home/europe.svg';
import AfricaIcon from '../assest/Home/africa.svg';
import AsiaIcon from '../assest/Home/asia.svg';
import AustraliaIcon from '../assest/Home/australia.svg';
// import AntarcticaIcon from '../assest/Home/antarctica.svg';

export const internationalMapData = [
  { label: 'North America', icon: NorthAmerica, value: 0 },
  { label: 'South America', icon: SouthAmerica, value: 1 },
  { label: 'Europe', icon: EuropeIcon, value: 2 },
  { label: 'Africa', icon: AfricaIcon, value: 3 },
  { label: 'Asia', icon: AsiaIcon, value: 4 },
  { label: 'Australia', icon: AustraliaIcon, value: 5 }
  // { label: 'Antarctica', icon: AntarcticaIcon, value: 6 }
];

export const domesticMapData = [
  { label: 'North India', value: 0 },
  { label: 'East India', value: 1 },
  { label: 'West India', value: 2 },
  { label: 'South India', value: 3 }
];
