import React, { useState } from 'react';
import '../../../assest/stylesheet/style.css';
import './ReviewCard.css';
import { Link } from 'react-router-dom';
import {
  Button,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  MobileStepper,
  Skeleton
} from '@mui/material';
import {
  AccountCircleRounded,
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Star
} from '@mui/icons-material';
import DoubleQuotes_icon from '../../../assest/ReviewCard/big-double-quotes.svg';
import Slider from 'react-slick';
import CustomModal from '../../common/CustomModal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';
import { IMAGE_DIR_PATH, PUBLIC_CMS_URL } from '../../../APIs/endpoint';

const ReviewCard = ({ review, continent, packageName, isLoader }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = review && review.review_image.length;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const settings = {
    autoplay: true,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setActiveStep(current)
  };

  return (
    <>
      <Box className="card-container">
        <Card className="review-card-container">
          <CardContent>
            <Grid container spacing={2}>
              <Grid container item xs={12} sm={12} md={7}>
                <Box className="review-card-content-box">
                  <Box className="star-content-box">
                    <Star />
                    {isLoader ? (
                      <Skeleton variant="text" width={50} height={25} />
                    ) : (
                      <span>{review.review_rating}</span>
                    )}
                  </Box>
                  {review.review_tour_theme?.map((item, index) => (
                    <Box className="outline-container" key={index} mr={1}>
                      {isLoader ? (
                        <Skeleton className="outline-text" variant="text" width={40} height={20} />
                      ) : (
                        <span className="outline-text">{item}</span>
                      )}
                    </Box>
                  ))}
                </Box>
                <Box
                  className="review-heading"
                  minWidth={review.review_description.length < 100 ? '670px' : ''}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}>
                  <Typography variant="h4">
                    {isLoader ? (
                      <Skeleton variant="text" width={40} height={20} />
                    ) : (
                      <>{review.title}</>
                    )}
                  </Typography>
                  <Typography minHeight={review.review_description.length < 100 ? '65px' : ''}>
                    {isLoader ? (
                      <Skeleton variant="text" width={250} height={65} />
                    ) : (
                      <>
                        {review.review_description.length > 100
                          ? review.review_description.slice(0, 100) + '...'
                          : review.review_description}
                      </>
                    )}
                  </Typography>

                  <Link
                    sx={{ color: '#27306B', fontWeight: 400, fontSize: 13, marginTop: '8px' }}
                    onClick={handleOpen}>
                    Read More
                  </Link>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="10px"
                  mt={{ md: '27px' }}
                  fontFamily="inherit">
                  {isLoader ? (
                    <Skeleton variant="circular" width={36} height={36} />
                  ) : (
                    <>
                      {review && review.user_image ? (
                        <img
                          src={`${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.USER_PROFILE_PIC_PATH}${review.user_image}`}
                          alt=""
                          width={36}
                          height={36}
                          style={{ borderRadius: '50%' }}
                        />
                      ) : (
                        <AccountCircleRounded sx={{ width: '36px', height: '36px' }} />
                      )}
                    </>
                  )}
                  <Box>
                    <Typography fontSize={13} fontWeight={700} lineHeight="20px" color="#000000">
                      {isLoader ? (
                        <Skeleton variant="text" width={60} height={30} />
                      ) : (
                        <> {review.review_username}</>
                      )}
                    </Typography>
                    <Typography fontSize={12} fontWeight={400} lineHeight="20px" color="#00000099">
                      {isLoader ? (
                        <Skeleton variant="text" width={40} height={20} />
                      ) : (
                        <> {moment(review.review_date).format('MMM, YYYY')}</>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid container item xs={12} sm={12} md={5}>
                <Box className="image-slider-container">
                  <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                    {isLoader ? (
                      <Skeleton
                        variant="rectangular"
                        width={350}
                        height={200}
                        sx={{ borderRadius: '6px' }}
                      />
                    ) : (
                      <>
                        {review.review_image.length > 1 ? (
                          <Slider {...settings}>
                            {review?.review_image.map((step, index) => (
                              <Box key={index}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                  <Box
                                    className="Hiren"
                                    component="img"
                                    sx={{
                                      minHeight: '215px',
                                      display: 'block',
                                      maxWidth: 663,
                                      overflow: 'hidden',
                                      width: '100%',
                                      borderRadius: '8px'
                                    }}
                                    src={`${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.REVIEW_LIST_PATH}${step}`}
                                    alt={index}
                                  />
                                ) : null}
                              </Box>
                            ))}
                          </Slider>
                        ) : (
                          <Box
                            component="img"
                            sx={{
                              height: 215,
                              display: 'block',
                              maxWidth: 400,
                              overflow: 'hidden',
                              width: '100%',
                              borderRadius: '8px'
                            }}
                            src={`${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.REVIEW_LIST_PATH}${review.review_image[0]}`}
                            alt="Review Image"
                          />
                        )}
                      </>
                    )}
                    {review.review_image.length > 1 && (
                      <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                          <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}>
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </Button>
                        }
                        backButton={
                          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowRight />
                            ) : (
                              <KeyboardArrowLeft />
                            )}
                          </Button>
                        }
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <CustomModal open={open} onClose={handleClose} title="European Explorer">
        <Box
          sx={{
            width: { xs: '300px', lg: '600px' },
            height: { xs: 'auto', lg: '445px' },
            bgcolor: '#FFFFFF',
            px: { xs: '25px', md: '50px' },
            py: '35px',
            fontFamily: 'inherit',
            borderRadius: '12px'
          }}>
          <Box display="flex" alignItems="center" gap={isMobile ? '10px' : '8px'}>
            <Box display="flex" gap={1}>
              <Star sx={{ color: '#F0C419', width: 16, height: 16 }} />
              <Typography fontWeight={700} fontSize={15} lineHeight="15px">
                {review.review_rating}
              </Typography>
            </Box>
            {review.review_tour_theme?.map((item, index) => (
              <Typography
                key={index}
                sx={{
                  color: '#E41E25',
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: '12px',
                  borderRadius: '6px',
                  border: '1px solid #E41E25',
                  padding: '4px'
                }}>
                {item}
              </Typography>
            ))}
            <Close onClick={handleClose} sx={{ ml: 'auto', cursor: 'pointer' }} />
          </Box>
          <Box mt="26px" maxHeight={{ md: '180px' }} pr="8px" sx={{ overflowY: { md: 'scroll' } }}>
            <Typography fontWeight={700} lineHeight="15px" fontSize={15}>
              {packageName ? `${packageName} Explorer` : `${continent}an Explorer`}
            </Typography>
            <Typography mt={2} fontWeight={400} fontSize={14} lineHeight="20px">
              {review.review_description}
            </Typography>
          </Box>
          <Box display="flex" gap="12px" pr="36px" mt={2}>
            {review &&
              review.review_image.map((item, index) => (
                <Box
                  key={index}
                  component="img"
                  src={`${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.REVIEW_LIST_PATH}${item}`}
                  alt="review-image"
                  flex={1}
                  width={{ xs: '150px' }}
                  height={{ xs: '130px' }}
                  sx={{ objectFit: 'cover' }}
                />
              ))}
          </Box>
          <Box mt="28px" display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="10px">
              <img
                src={
                  review && review.user_image
                    ? `${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.USER_PROFILE_PIC_PATH}${review.user_image}`
                    : './icons/review-user.svg'
                }
                alt="userProfile"
                width={36}
                height={36}
                style={{ borderRadius: '50%' }}
              />
              <Box>
                <Typography fontWeight={700} fontSize={13} lineHeight="20px">
                  {review.review_username}
                </Typography>
                <Typography fontWeight={400} fontSize={12} lineHeight="20px">
                  {moment(review.review_date).format('MMM, YYYY')}
                </Typography>
              </Box>
            </Box>
            <img src={DoubleQuotes_icon} alt="qoute" />
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default ReviewCard;
